import React from "react";
import styled from "styled-components";
import { Typography, Space, Popconfirm } from "antd";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

export const GhostText = styled.span`
  opacity: 0.7;
`;

const { Text } = Typography;

const columns = (
  setModal = () => {},
  setPartner = () => {},
  handleDelete = () => {},
  setParnetLogId = () => {}
) => [
  {
    title: "ФИО",
    dataIndex: "fio",
    render: (e) => {
      return e ? e : <GhostText>(отсутствует)</GhostText>;
    },
  },
  {
    title: "Название компании",
    dataIndex: "title",
    render: (e) => {
      return e ? e : <GhostText>(отсутствует)</GhostText>;
    },
  },
  {
    title: "Email",
    dataIndex: "mail",
    render: (e) => {
      return e ? (
        <Text copyable>{e}</Text>
      ) : (
        <GhostText>(отсутствует)</GhostText>
      );
    },
  },
  {
    title: "Интересы",
    dataIndex: "interest",
    render: (e) => {
      return e ? e : <GhostText>(отсутствует)</GhostText>;
    },
  },
  {
    title: "Города",
    dataIndex: "cities_count",
    render: (e, props = {}) => {
      return props.is_global ? (
        <div>Для всех городов</div>
      ) : e ? (
        <div>
          {e}
          <div
            onClick={() => setModal({ ...props })}
            style={{
              background: "#5c5ef9",
              fontSize: "10px",
              color: "white",
              maxWidth: "max-content",
              padding: "3px",
              borderRadius: "3px",
              cursor: "pointer",
            }}
          >
            Открыть
          </div>
        </div>
      ) : (
        <GhostText>(отсутствует)</GhostText>
      );
    },
  },
  {
    title: "Комментарий",
    dataIndex: "comment",
    render: (e) => {
      return e ? e : <GhostText>(отсутствует)</GhostText>;
    },
  },
  {
    title: "Действия",
    dataIndex: "",
    render: (e, { id }) => {
      return (
        <Space direction="vertical">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPartner(id);
              setParnetLogId(uuidv4());
            }}
          >
            Изменить
          </div>

          <Popconfirm
            title="Удалить партнера?"
            onConfirm={() => handleDelete(id)}
            onCancel={() => {}}
            okText="Да"
            cancelText="Нет"
          >
            <div style={{ cursor: "pointer", color: "red" }}>Удалить</div>
          </Popconfirm>
        </Space>
      );
    },
  },
];

export default columns;
