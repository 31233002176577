import React, { useMemo, useEffect, useState } from "react";
import { Skeleton, Transfer as AntTrasfer } from "antd";
import { TransWrapper } from "./__styles";

import makeQuery from "../components/query";
import { GET_MEMBERS } from "../../../../../queries/5-steps";

const Transfer = (props = {}) => {
  const { value, onChange = () => {} } = props;

  const [search, setSearch] = useState();
  const [getMembers, { data: membersData, loading }] = makeQuery(GET_MEMBERS);

  useEffect(() => {
    getMembers({ variables: { search } });
  }, [search]);

  const tempMockData = useMemo(() => {
    if (membersData) {
      const { app5s_userPerformers: a = [] } = membersData;

      return a
        .map((item = {}) => {
          const { user_id, user = {} } = item;
          const { meta = [] } = user;
          const [user_meta = {}] = meta;
          const { first_name = "", last_name = "" } = user_meta;

          return { key: user_id, title: `${first_name} ${last_name}` };
        })
        .sort((_a = {}, _b = {}) => {
          let { title: a } = _a;
          let { title: b } = _b;

          a = a ? a.toLowerCase() : "";
          b = b ? b.toLowerCase() : "";

          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });
    } else {
      return [];
    }
  }, [membersData]);

  const filterOption = (inputValue, option) => {
    const { title } = option;
    const tags = inputValue
      ? inputValue.split(" ").filter((v) => v && v !== "")
      : [];

    let res = true;
    tags.map((word = "") => {
      if (title && !title.toLowerCase().includes(word.toLowerCase()))
        res = false;
    });

    return res;
  };
  const handleSearch = (dir, value) => {};

  return (
    <TransWrapper>
      {loading && <Skeleton active></Skeleton>}
      {!loading && (
        <AntTrasfer
          dataSource={tempMockData}
          showSearch={true}
          filterOption={filterOption}
          targetKeys={
            value && tempMockData && tempMockData.length > 0
              ? value.sort((id1, id2) => {
                  const item1 = tempMockData.find(({ key }) => key === id1);
                  const item2 = tempMockData.find(({ key }) => key === id2);

                  if (item1 && item2) {
                    let { title: a } = item1;
                    let { title: b } = item2;

                    a = a ? a.toLowerCase() : "";
                    b = b ? b.toLowerCase() : "";

                    if (a < b) {
                      return -1;
                    }
                    if (a > b) {
                      return 1;
                    }
                    return 0;
                  }
                })
              : value
          }
          onChange={onChange}
          onSearch={handleSearch}
          render={(item) => (
            <div style={{ maxWidth: "120px", fontSize: "12px" }}>
              {item.title}
            </div>
          )}
        />
      )}
    </TransWrapper>
  );
};

export default Transfer;
/* const handleSearch = (str) => {
    if (str) {
      let finalStr;

      const formattedStr = str.split(" ");
      finalStr =
        formattedStr.length > 0 && formattedStr[0] !== ""
          ? "%" +
            formattedStr.reduce((prev, key) => prev + "%" + key + "%") +
            "%"
          : null;

      setSearch(finalStr);
    }
  }; */
