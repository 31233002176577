import React from "react";
import moment from "moment";

const columns = [
  {
    title: "Город",
    dataIndex: "city_name",
    render: (e) => e,
  },
  {
    title: "Отчет по контрольной точке 1",
    dataIndex: "point1",
    render: (e) =>
      e ? (
        <a href={e} target="_blank">
          Скачать файл
        </a>
      ) : (
        <>(Отсутствует)</>
      ),
  },
  {
    title: "Время загрузки файла",
    dataIndex: "cr1",
    render: (e, { point1 }) =>
      point1 ? moment(e).format("dddd, MMMM Do YYYY, HH:mm:ss") : "—",
  },
  {
    title: "Отчет по контрольной точке 2",
    dataIndex: "point2",
    render: (e) =>
      e ? (
        <a href={e} target="_blank">
          Скачать файл
        </a>
      ) : (
        <>(Отсутствует)</>
      ),
  },
  {
    title: "Время загрузки файла",
    dataIndex: "cr2",
    render: (e, { point2 }) =>
      point2 ? moment(e).format("dddd, MMMM Do YYYY, HH:mm:ss") : "—",
  },
  {
    title: "Отчет по контрольной точке 3",
    dataIndex: "point3",
    render: (e) =>
      e ? (
        <a href={e} target="_blank">
          Скачать файл
        </a>
      ) : (
        <>(Отсутствует)</>
      ),
  },
  {
    title: "Время загрузки файла",
    dataIndex: "cr3",
    render: (e, { point3 }) =>
      point3 ? moment(e).format("dddd, MMMM Do YYYY, HH:mm:ss") : "—",
  },
];

export default columns;
