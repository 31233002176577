/**
 * Internal Dependencies
 */
// SignIn
import SignInPage from "./Auth/SignIn";
import SignUpPage from "./Auth/SignUp";
import ForgotPage from "./Auth/Forgot";
import RecoveryPage from "./Auth/Recovery";

// Crowdsourcing
// import CrowdsourcingMap from "./Crowdfunding/Crowdsourcing/Map";

// import FAQPage from "./Crowdfunding/Crowdsourcing/FAQ";

// Apps
import MembersPage from "./Crowdfunding/Crowdsourcing/5Steps/members";
import ExecutorsPage from "./Crowdfunding/Crowdsourcing/5Steps/executors";
import ProjectsKBPage from "./Crowdfunding/Crowdsourcing/5Steps/projects-kb";
import ProjectsCitiesPage from "./Crowdfunding/Crowdsourcing/5Steps/projects-cities";
import PlansPage from "./Crowdfunding/Crowdsourcing/5Steps/plans";
import ReportsPage from "./Crowdfunding/Crowdsourcing/5Steps/reports";
import IdeasPage from "./Crowdfunding/Crowdsourcing/5Steps/citizens-ideas";
import IConPage from "./Crowdfunding/Crowdsourcing/5Steps/interests-consideration";
import MapPage from "./Crowdfunding/Crowdsourcing/5Steps/map";
import PartnersPage from "./Crowdfunding/Crowdsourcing/5Steps/partners";

export default {
  "/": MembersPage,
  "/sign-in": SignInPage,
  "/sign-up": SignUpPage,
  "/forgot": ForgotPage,
  "/recovery": RecoveryPage,
  // "/": CrowdsourcingProject,
  // "/map": CrowdsourcingMap,
  "/project/members": MembersPage,
  "/project/partners": PartnersPage,
  "/project/executors": ExecutorsPage,
  "/project/projects-kb": ProjectsKBPage,
  "/project/projects-cities": ProjectsCitiesPage,
  "/project/plans": PlansPage,
  "/project/interests-consideration": IConPage,
  "/project/reports": ReportsPage,
  "/project/citizens-ideas": IdeasPage,
  "/project/map": MapPage,
  // "/help": FAQPage
};
