import React from "react";
import styled from "styled-components";
import { Input, Row, Col } from "antd";
import useStatusStore from "../../../../../store/status-store";
import { useLocation } from "react-router-dom";

const Search = styled(Input.Search)`
  & {
    & .anticon-close-circle {
      transform: translateY(-2px) scale(1.5);
    }

    & input[type="text"] {
      height: 28px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }

      & {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    & button {
      border: 0px;
      height: 36px;
      width: 60px;
    }

    & .ant-input-affix-wrapper {
      background: white;
    }

    & button,
    & button:hover,
    & button:focus {
      background: #5c5ef9;
      color: white;
      & * {
        color: white;
      }
    }
  }
`;

const SearchBar = ({ noformat = false }) => {
  const setSearch = useStatusStore(({ setSearch }) => setSearch);
  const search = useStatusStore(({ search }) => search);

  let location = useLocation();
  const { pathname = "" } = location;

  const handleSearch = (str) => {
    let finalStr;

    if (!noformat) {
      const formattedStr = str.split(" ");
      finalStr =
        formattedStr.length > 0 && formattedStr[0] !== ""
          ? "%" +
            formattedStr.reduce((prev, key) => prev + "%" + key + "%") +
            "%"
          : null;
    } else {
      finalStr = str;
    }

    setSearch(finalStr);
  };

  return (
    <Row style={{ width: "100%" }}>
      <Col style={{ width: "100%", maxWidth: "520px" }}>
        <Search
          defaultValue={search}
          bordered={false}
          allowClear
          onSearch={handleSearch}
          placeholder={
            pathname === "/project/partners"
              ? "Поиск по партнерам"
              : "Поиск по городу"
          }
        />
      </Col>
    </Row>
  );
};

export default SearchBar;
