import React from "react";
import styled from "styled-components";
import { citiesExtraMeta } from "./extra-meta";
import { Space, Tooltip, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";

export const GhostText = styled.span`
  opacity: 0.7;
`;

const { Text } = Typography;

export const handlePassword = (email) => {
  const item = citiesExtraMeta.find(
    ({ email: localEmail }) => localEmail === email
  );

  const { password } = item ? item : {};
  return password;
};

const columns = (
  setModal = () => {},
  setPartner = () => {},
  setParnetLogId = () => {},
  disabledEdit = false
) => [
  {
    title: "Название города",
    dataIndex: "fullname",
    render: (e) => {
      return e;
    },
  },
  {
    title: "Группа",
    dataIndex: "occupation",
    sorter: (a, b) => a.group - b.group,
    render: (e) => {
      e =
        (e === "A" ||
          e === "B" ||
          e === "C" ||
          e === "D" ||
          e === "E" ||
          e === "F") &&
        e;

      return e ? e : <GhostText>(отсутствует)</GhostText>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    render: (e) => {
      return e ? (
        <Text copyable>{e}</Text>
      ) : (
        <GhostText>(отсутствует)</GhostText>
      );
    },
  },
  {
    title: "Код доступа",
    dataIndex: "password",
    render: (e, { email }) => {
      //const password = handlePassword(email);

      return e ? (
        <Text copyable>{e}</Text>
      ) : (
        <GhostText>(отсутствует)</GhostText>
      );
    },
  },
  {
    title: "Действия",
    dataIndex: "",
    render: (e, { id }) => {
      return (
        <Space direction="vertical">
          {!disabledEdit ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setPartner(id);
                setParnetLogId(uuidv4());
              }}
            >
              Изменить
            </div>
          ) : (
            <Tooltip title="Аккаунт не может редактироваться, пока он не станет 'Активным участником'. Для этого в account.5stepsforcities.ru необходимо для него заполнить раздел 'Информация об ответственных лицах'">
              <div style={{ cursor: "pointer" }} onClick={() => {}}>
                Изменить
              </div>
            </Tooltip>
          )}
        </Space>
      );
    },
  },
];

export default columns;
