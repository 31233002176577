import React, { useRef, useState, useEffect, useMemo } from "react";
import PageWrapper from "..";
import useMapCreate from "./hooks/use-map-create";
import russianCities from "./data/russian-cities.json";
import mapboxgl from "!mapbox-gl";

import {
  GET_MEMBERS,
  GET_PROJECTSKB,
  GET_ALL_PROJECTS,
} from "../../../../../queries/5-steps";
import makeQuery from "../components/query";
import useStatusStore from "../../../../../store/status-store";
import handleData from "../members/handleData";
import handleDataKB from "../projects-kb/handleData";
import handleAttractionsLayer from "./hooks/handle-attractions-layer";
import handleRegionLayer from "./hooks/handle-region-layer";
import { Space } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Segmented } from "antd";
import styled from "styled-components";

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

import mapGeom from "./data/mapGeom.json";

export const handleHTML = ({ city_name }) => {
  return `<div style="display: flex; flex-direction: column; max-width: 350px">
  <div style="display: flex; align-items: center; ">
    
    <span style="color: black; font-size: 12px; text-align: center; font-weight: 500"><div></div>${city_name}</span>
  </div>
  
  
  </div>`;
};

const Label = styled.div`
  && {
    display: flex;
    height: 100%;
    align-items: center;
  }

  ${({ fill }) =>
    fill
      ? `
  &::before {
    content: "";
    min-width: 10px;
    height: 10px;
    background: ${fill};
    border-radius: 50%;
    margin-right: 6px;
  }
  `
      : `
      &::before {
        content: "";
        min-width: 1px;
        height: 10px;
        background: white
      }
      `}
`;

const Seg = styled(Segmented)`
  && {
    &&,
    &&:hover {
      background-color: rgb(223 223 223);
    }

    & .ant-segmented-item-label {
      display: flex;
      align-items: center;
      padding: 0 10px;
    }

    & .ant-segmented-item {
      &,
      & div {
        height: 34px;
      }

      margin-bottom: 0px;

      &,
      & * {
        font-size: 11px;
        color: black;
      }
    }
  }
`;

const MapPage = () => {
  const search = useStatusStore(({ search }) => search);

  const [getMembers, { data: membersData, loading: membersLoading }] =
    makeQuery(GET_MEMBERS);
  const [getProjectKB, { data, loading: loadingKB }] =
    makeQuery(GET_PROJECTSKB);
  const [getAllProjects, { data: allProjects, loading: loadingAll }] =
    makeQuery(GET_ALL_PROJECTS);

  useEffect(() => {
    getMembers({ variables: { search } });
    getProjectKB({ variables: { search } });
    getAllProjects();
  }, [search]);

  const handleAttrHover = (e, map) => {
    const features = map.queryRenderedFeatures(e.point);

    const attrFeatures = features.filter(
      ({ source }) => source === "attractions"
    );

    const popup = document.getElementsByClassName("mapboxgl-popup");

    if (popup.length) {
      popup[0].remove();
    }

    if (attrFeatures && attrFeatures.length > 0) {
      const attrItem = attrFeatures[0];
      const { geometry = {}, properties = {} } = attrItem ? attrItem : {};
      const { coordinates = [] } = geometry;

      const { city_name } = properties;

      new mapboxgl.Popup({ offsets: [0, 0], closeButton: false })
        .setLngLat(coordinates)
        .setHTML(handleHTML({ city_name }))
        .addTo(map);
    }
  };

  const handleAttrUnhover = (e, map) => {
    const popup = document.getElementsByClassName("mapboxgl-popup");

    if (popup.length) {
      popup[0].remove();
    }
  };

  const [category, setCategory] = useState("Все");

  const members = useMemo(() => {
    if (membersData && data && allProjects) {
      let resultMembers = handleData(membersData, true);
      let resultProjectsKB = handleDataKB(data, allProjects, true);

      const result = resultMembers
        .map((item = {}, i) => {
          const { fullname, user_id } = item;

          const name = fullname && fullname.split("qqq");

          let lon, lat, hasProjects;

          if (name) {
            const foundCityKB = resultProjectsKB.find(
              ({ city_id }) => city_id === user_id
            );
            if (foundCityKB) {
              const { step1, step2, step3, step4, step5 } = foundCityKB;

              if (
                step1?.length > 0 ||
                (step2?.length > 0 && step3?.length > 0) ||
                step4?.length > 0 ||
                step5?.length > 0
              ) {
                hasProjects = true;
              }
            }

            const foundCityJSON = russianCities.find(({ name: _name }) => {
              return _name === name[0];
            });

            if (foundCityJSON) {
              const { coords = {} } = foundCityJSON ? foundCityJSON : {};
              const { lat: _lat, lon: _lon } = coords;

              lat = typeof _lat === "string" ? parseFloat(_lat) : null;
              lon = typeof _lon === "string" ? parseFloat(_lon) : null;
            }
          }

          let city_name;
          if (name) {
            city_name = `${name[0]} ${name[1] ? name[1] : ""}`;
          }

          if (typeof lat === "number" && typeof lon === "number") {
            return {
              id: i,
              type: "Feature",
              properties: {
                city_id: user_id,
                city_name,
                size: 1,
                hasProjects: hasProjects ? "has" : "none",
              },
              geometry: {
                type: "Point",
                coordinates: [lon, lat],
              },
            };
          }
        })
        .filter((a) => a);

      return result;
    }
  }, [membersData, data, allProjects]);

  const filteredMembers = useMemo(() => {
    if (category === "Все") {
      return members;
    } else {
      return members.filter(({ properties = {} }) => {
        const { hasProjects } = properties;

        if (category === "Выбрали проекты") {
          return hasProjects === "has";
        } else {
          return hasProjects === "none";
        }
      });
    }
  }, [members, category]);

  const [map, setMap] = useState(null);
  const [mapPreloaded, setMapPreloaded] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapStyled, setMapStyled] = useState(false);

  const mapRef = useRef();

  useEffect(() => {
    setMapPreloaded(true);
  }, []);

  useMapCreate({
    mapPreloaded,
    mapRef,
    zoom: 2.0549661994141507,
    center: [98.10606143680025, 64.68612023074468],
    onLoad: (map) => {
      setMap(map);
      setMapLoaded(true);

      handleRegionLayer({ map, mapGeom });
      handleAttractionsLayer({ map });

      /* Шаг 2.1: Выбираем слои и подчищаем лишние элементы */
      //useLayerClearing(map);

      setMapStyled(true);

      map.on("mousemove", "l_attrs", (e) => handleAttrHover(e, map));
      map.on("mouseleave", "l_attrs", (e) => handleAttrUnhover(e, map));
    },
  });

  useEffect(() => {
    if (map && mapLoaded && members) {
      if (map.getSource("attractions")) {
        const attractionsSource = map.getSource("attractions");

        attractionsSource.setData({
          type: "FeatureCollection",
          features: filteredMembers,
        });
      }
    }
  }, [map, mapLoaded, filteredMembers]);

  return (
    <>
      <PageWrapper
        size={"nosize"}
        minheight={"none"}
        title="Карта с городами-участниками"
      >
        <div
          style={{ width: "100%", paddingBottom: "50%", position: "relative" }}
        >
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <div
              ref={mapRef}
              style={{
                width: "100%",
                height: "100%",
                right: 0,
                bottom: 0,
                transition: "all .2s ease-in-out",
              }}
            ></div>
          </div>

          <div
            style={{
              position: "absolute",
              left: "10px",
              top: "10px",
              zIndex: 10,
            }}
          >
            <Seg
              defaultValue={category}
              onChange={(e) => {
                setCategory(e);
              }}
              options={[
                { label: <Label>Все</Label>, value: "Все" },
                {
                  label: <Label fill={`#5c5ef9`}>Выбрали проекты</Label>,
                  value: "Выбрали проекты",
                },
                {
                  label: <Label fill={`#ACACAC`}>Без проектов</Label>,
                  value: "Без проектов",
                },
              ]}
            />
          </div>

          {!members && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 20,
                background: "rgb(255, 255, 255 ,.6)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  background: "white",
                  padding: "10px",
                  width: "150px",
                  height: "150px",
                  borderRadius: "16px",
                  border: "1px solid grey",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Space direction="vertical" align="center">
                  <div>
                    <Spin indicator={antIcon} />
                  </div>
                  <div style={{ fontSize: "11px", textAlign: "center" }}>
                    Заружаются данные
                  </div>
                </Space>
              </div>
            </div>
          )}
        </div>
      </PageWrapper>
    </>
  );
};

export default MapPage;
