/**
 * External Dependencies
 */
import "./style.scss";

import React, { Component } from "react";
import classnames from "classnames/dedupe";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import { loadCurrentProject } from "../../actions/app";

import styled from "styled-components";

/**
 * Internal Dependencies
 */
import { updateSettings as actionUpdateSettings } from "../../actions";
import { authLogout } from "../../actions/auth";
import Dropdown from "../bs-dropdown";
import Icon from "../icon";
import { initNavbar } from "../../common-assets/js/rootui-parts/initNavbar";
import { pick } from "../../helpers/fn";
import SearchBar from "../../pages/Crowdfunding/Crowdsourcing/5Steps/components/search";

const $ = window.jQuery;

window.RootUI.initNavbar = initNavbar;

const NavbarBrand = styled.div`
  && {
    background: #2d2d2d !important;
  }
`;

const NavBlock = styled.nav`
  &&&& {
    border-bottom: 0px !important;
    background: #EEEEEE;
  }
`;

/**
 * Component
 */
class PageNavbar extends Component {
  constructor(props) {
    super(props);

    window.RootUI.initNavbar();

    this.state = {
      mobileMenuShow: false,
    };

    this.logOut = this.logOut.bind(this);
    this.renderSubmenus = this.renderSubmenus.bind(this);
    this.renderRightMenuItems = this.renderRightMenuItems.bind(this);
  }

  componentDidMount() {
    $(document).on("keydown.rui-navbar", (e) => {
      const { mobileMenuShow } = this.state;

      if (mobileMenuShow && e.keyCode === 27) {
        this.setState({
          mobileMenuShow: !mobileMenuShow,
        });
      }
    });
  }

  componentWillUnmount() {
    $(document).off("keydown.rui-navbar");
  }

  logOut() {
    const { authLogout } = this.props;

    authLogout();
  }

  renderSubmenus(nav, isMobile, level = 1) {
    return Object.keys(nav).map((url) => {
      const data = nav[url];

      const isActive = window.location.hash === `#${url}`;

      const LinkContent = data.name ? (
        <>
          {data.icon ? (
            <>
              <Icon name={data.icon} />
              <span>{data.name}</span>
              <span
                className={data.sub ? "rui-dropdown-circle" : "rui-nav-circle"}
              />
            </>
          ) : (
            data.name
          )}
        </>
      ) : (
        ""
      );

      return (
        <React.Fragment key={`${url}-${data.name}`}>
          {data.divider ? (
            <li className="nav-divider">
              <hr />
            </li>
          ) : (
            ""
          )}

          {data.label ? <li className="nav-label">{data.label}</li> : ""}

          {data.sub ? (
            <Dropdown
              tag="li"
              className={classnames(isActive ? "active" : "")}
              direction={level === 1 ? "up" : "right"}
              openOnHover
              showTriangle
            >
              <Dropdown.Toggle tag="a" href="#" className="dropdown-item">
                {LinkContent}
              </Dropdown.Toggle>
              <Dropdown.Menu tag="ul" className="nav dropdown-menu">
                {this.renderSubmenus(data.sub, isMobile, level + 1)}
                <li>
                  <Link className="nav-link" to="/project/create">
                    <Icon name="plus" />
                    <span>Новый проект</span>
                    <br />
                  </Link>
                </li>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <li className={classnames("nav-item", isActive ? "active" : "")}>
              <Link
                to={data.sub || data.onClick ? "#" : url}
                className="nav-link"
                onClick={data.onClick ? data.onClick : undefined}
              >
                {LinkContent}
              </Link>
            </li>
          )}
        </React.Fragment>
      );
    });
  }

  renderRightMenuItems() {
    return (
      <li className="dropdown">
        <Link
          to="/sign-in"
          className="nav-link dropdown-item"
          onClick={this.logOut}
        >
          <Icon name="log-out" />
          <span>Выход</span>
          <span className="rui-dropdown-circle" />
        </Link>
      </li>
    );
  }

  detectTool() {
    const { tools, location } = this.props;

    const toolUri = /\/project\/tools\/(.*)/;
    const toolMatch = location?.pathname?.match(toolUri);

    if (toolMatch) {
      const tool = tools.find((item) => item.id === toolMatch[1]);
      const prefix = tool?.type_name ? tool.type_name : "";
      return tool?.data?.title
        ? (prefix ? prefix + ": " : "") + tool.data.title
        : prefix;
    }

    return null;
  }

  render() {
    const {
      auth,
      settings,
      projects,
      currentProject,
      currentProjectId,
      loadCurrentProject,
    } = this.props;
    const {
      nav_container_fluid,
      nav_dark,
      nav_expand,
      nav_fixed,
      nav_logo_alt,
      nav_logo_height,
      nav_logo_path,
      nav_logo_url,
      nav_logo_white_path,
      nav_logo_width,
      nav_sticky,
      navigation,
    } = { ...settings };

    const { mobileMenuShow } = this.state;

    const showYayToggle = !auth.is_preparing;

    const toolName = this.detectTool();

    const projectName = pick("name", currentProject, "");
    const navigationMenu = projects?.length
      ? {
          "#projects": {
            name:
              `Текущий проект: ${projectName}` +
              (toolName ? ` / ${toolName}` : ""),
            icon: "layers",
            sub: {
              ...projects.slice(0, 8).reduce((menu, item) => {
                const projectLink = "/projects/" + item.project_id;
                const itemIcon =
                  item.project_id === currentProjectId
                    ? "check-circle"
                    : "circle";
                menu[projectLink] = {
                  name: item.name,
                  icon: itemIcon,
                  onClick: () => {
                    loadCurrentProject(item.project_id);
                  },
                };
                return menu;
              }, {}),
            },
          },
          ...navigation,
        }
      : {};

    if (projects.length > 8) {
      navigationMenu["#projects"].sub = {
        ...navigationMenu["#projects"].sub,
        "/projects": {
          divider: true,
          name: `Перейти ко всем проектам (всего ${projects.length})`,
          icon: "more-horizontal",
        },
      };
    }

    return (
      <>
        {/* Nav Menu */}
        <NavBlock
          className={classnames(
            "rui-navbar rui-navbar-top",
            nav_dark ? "rui-navbar-dark" : "",
            nav_sticky ? "rui-navbar-sticky" : "",
            nav_fixed ? "rui-navbar-fixed" : "",
            nav_expand ? `rui-navbar-expand-${nav_expand}` : ""
          )}
        >
          <NavbarBrand className="rui-navbar-brand">
            <Link to={nav_logo_url} className="rui-navbar-logo">
              <img
                src={nav_logo_white_path}
                alt={nav_logo_alt}
                width={nav_logo_width}
                height={nav_logo_height}
              />
            </Link>
          </NavbarBrand>

          {/* Правая часть */}
          {
            <div
              className={`container${nav_container_fluid ? "-fluid" : ""}`}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "0 15px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%" }}>
                  <SearchBar />
                </div>
                <div>{this.renderRightMenuItems(false, showYayToggle)}</div>
              </div>
            </div>
          }
        </NavBlock>

        <div
          className="rui-navbar-bg"
          onClick={() => {
            this.setState({
              mobileMenuShow: !mobileMenuShow,
            });
          }}
          onKeyUp={() => {}}
          role="button"
          tabIndex={0}
        />
      </>
    );
  }
}

export default connect(
  ({ app, auth, settings }) => ({
    auth,
    settings,
    tools: app.tools,
    projects: app.projects,
    currentProject: app.currentProject,
    currentProjectId: app.currentProjectId,
  }),
  {
    authLogout,
    updateSettings: actionUpdateSettings,
    loadCurrentProject,
  }
)(PageNavbar);
