import React, { useEffect, useMemo, useState } from "react";

import columns from "./columns";
import columnsWire from "../wire/columns";
import {
  GET_PROJECTSKB,
  GET_ALL_PROJECTS,
} from "../../../../../queries/5-steps";
import makeQuery from "../components/query";
import { CustomTable } from "../index";

import PageWrapper from "../index";
import SearchBar from "../components/search";

import { TotalCounter } from "../members";
import ExcelTable from "../components/excel";
import handleData from "./handleData";
import useStatusStore from "../../../../../store/status-store";

const ProjectsKBPage = () => {
  const search = useStatusStore(({ search }) => search);

  const [getProjectKB, { data, loading }] = makeQuery(GET_PROJECTSKB);
  const [getAllProjects, { data: allProjects, loading: loading1 }] =
    makeQuery(GET_ALL_PROJECTS);

  useEffect(() => {
    getProjectKB({ variables: { search } });
    getAllProjects();
  }, [search]);

  const projectsKB = useMemo(() => {
    return handleData(data, allProjects);
  }, [data, allProjects]);

  return (
    <PageWrapper title="Проекты КБ">
      {/* <SearchBar {...{ setSearch }} /> */}

      {projectsKB && (
        <TotalCounter>
          Всего записей по городам: {projectsKB?.length}{" "}
        </TotalCounter>
      )}
      {projectsKB && <ExcelTable />}
      <br />
      <br />

      {loading || loading1 ? (
        <CustomTable dataSource={Array(12).fill(1)} columns={columnsWire} />
      ) : (
        <CustomTable dataSource={projectsKB} columns={columns} />
      )}
    </PageWrapper>
  );
};

export default ProjectsKBPage;
