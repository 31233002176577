import React from "react";
import styled from "styled-components";
import { Collapse, Space } from "antd";

const { Panel } = Collapse;

import { GhostText } from "../members/columns";

const Tg = styled.div`
  max-width: 200px;
  font-size: 11px;
  background-color: ${({ color }) => (color ? color : "lightgrey")};
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 5px;
  width: 100%;
  color: white;
`;

const CardsCollapse = styled(Collapse)`
  && .ant-collapse-header {
    font-size: 11px;
  }

  && .ant-collapse-content-box,
  && .ant-collapse-header {
    padding: 3px;
  }

  && .ant-collapse-header {
    padding-left: 20px;
    font-weight: 500;
  }

  &&&&&&&&&&&& .ant-collapse-arrow {
    top: 7px;
    left: 4px;
    display: inline-block;
    font-size: 9px;
  }

  &&& .ant-collapse-content {
    border: 0px;
  }
`;

const handleStepProjects = (stepIndex = [], allProps = {}, step) => {
  const { unused = {}, own = {} } = allProps;
  const unusedKeys = unused[`step${step}`] ? unused[`step${step}`] : [];
  const ownKeys = own[`step${step}`] ? own[`step${step}`] : [];

  let backFill;
  switch (step) {
    case 1:
      backFill = "rgba(28, 178, 172, 1)";
      break;
    case 2:
      backFill = "rgba(65, 178, 86, 1)";
      break;
    case 3:
      backFill = "rgba(253, 133, 36, 1)";
      break;
    case 4:
      backFill = "rgba(42, 157, 214, 1)";
      break;
    case 5:
      backFill = "rgba(134, 101, 246, 1)";
      break;
  }

  return (
    <Space direction="vertical">
      <CardsCollapse accordion defaultActiveKey={1}>
        <Panel header="Выбранные проекты" key="1">
          {stepIndex.map(({ is_priority, name, project_id }) => {
            return (
              <Tg key={`tag:${project_id}`} color={backFill}>
                {name}
                {is_priority ? "*" : ""}
              </Tg>
            );
          })}
        </Panel>
      </CardsCollapse>

      <CardsCollapse accordion>
        <Panel header="Невыбранные проекты" key="1">
          {unusedKeys.map(({ title, project_id }) => {
            return (
              <Tg
                key={`tag:${project_id}`}
                color={backFill}
                data-filter="greyscale"
              >
                {title}
              </Tg>
            );
          })}
        </Panel>
      </CardsCollapse>

      <CardsCollapse accordion>
        <Panel header="Предложенные проекты" key="1">
          {ownKeys.length > 0 ? (
            ownKeys.map(({ is_priority, title, project_id }) => {
              return (
                <Tg key={`tag:${project_id}`} color={backFill}>
                  {title}
                  {is_priority ? "*" : ""}
                </Tg>
              );
            })
          ) : (
            <GhostText style={{ fontSize: "12px" }}>(отсутствуют)</GhostText>
          )}
        </Panel>
      </CardsCollapse>
    </Space>
  );
};

const columns = [
  {
    title: "Город",
    dataIndex: "city_name",
    render: (e) => e,
    width: "10%",
  },
  {
    title: "Шаг 1",
    dataIndex: "step1",
    render: (a, b) => handleStepProjects(a, b, 1),
    width: "18%",
  },
  {
    title: "Шаг 2",
    dataIndex: "step2",
    render: (a, b) => handleStepProjects(a, b, 2),
    width: "18%",
  },
  {
    title: "Шаг 3",
    dataIndex: "step3",
    render: (a, b) => handleStepProjects(a, b, 3),
    width: "18%",
  },
  {
    title: "Шаг 4",
    dataIndex: "step4",
    render: (a, b) => handleStepProjects(a, b, 4),
    width: "18%",
  },
  {
    title: "Шаг 5",
    dataIndex: "step5",
    render: (a, b) => handleStepProjects(a, b, 5),
    width: "18%",
  },
];

export default columns;
