import React from "react";
import styled from "styled-components";
import PageWrap from "../../../../components/page-wrap";
import PageContent from "../../../../components/page-content";
import { Table } from "antd";
import { StyledH2 } from "../Styled/styled";

const WhiteBlockWrapper = styled.div`
  background: white;
  width: 100%;
  max-width: 1900px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  min-height: 600px;

  &[data-minheight="none"] {
    min-height: 0px;
  }

  &[data-size="small"] {
    padding: 20px 30px;
  }

  &[data-size="large"] {
    padding: 30px 40px;
  }

  &[data-size="nosize"] {
    padding: 0;
  }

  &[data-type="steps"] {
    width: clamp(250px, 18vw, 300px);
  }
`;

export const WhiteBlock = ({
  children,
  minheight = "default",
  size = "large",
  type,
}) => {
  return (
    <WhiteBlockWrapper
      data-size={size}
      data-type={type}
      data-minheight={minheight}
    >
      {children}
    </WhiteBlockWrapper>
  );
};

const StyledPageWrap = styled(PageWrap)`
  background: #eeeeee !important;
`;

export const CustomTable = styled(Table)`
  && .ant-table-thead .ant-table-cell {
    background: #dfe4ff;

    & .ant-table-column-sorters {
      padding: 0;
    }

    &,
    & * {
      color: #5c5ef9;
    }
  }

  && .ant-table-thead > tr > th {
    padding: 10px 16px;
  }

  && .ant-table-row-expand-icon {
    transform: scale(1.2);
  }

  &&&&&& th,
  &&&&&& td {
    margin-right: 20px;
    font-size: 14px;
    line-height: 1.2;
    vertical-align: baseline;
  }
`;

const PageWrapper = ({
  children,
  title = "Название раздела",
  ...otherProps
}) => {
  return (
    <StyledPageWrap>
      <PageContent>
        <StyledH2 data-level={1} bottom={0}>
          {title}
        </StyledH2>

        <WhiteBlock {...otherProps}>{children}</WhiteBlock>
      </PageContent>
    </StyledPageWrap>
  );
};

export default PageWrapper;
