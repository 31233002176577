import { gql } from "@apollo/client";

const GET_MEMBERS = gql`
  query getMembers($search: String) {
    app5s_userPerformers(
      distinct_on: user_id
      where: {
        _or: [
          { user: { meta: { first_name: { _ilike: $search } } } }
          { user: { meta: { last_name: { _ilike: $search } } } }
        ]
      }
    ) {
      id
      user_id
      cr
      account {
        password
      }
      user {
        meta {
          email
          first_name
          last_name
          occupation
        }
      }
    }
  }
`;

export const GET_NEW_MEMBERS = gql`
  query getNewMembers($search: String) {
    app5s_userAccounts(
      order_by: { user_meta: { cr: desc_nulls_last } }
      where: {
        _not: { performer: { user_id: { _is_null: false } } }
        _or: [
          { user_meta: { first_name: { _ilike: $search } } }
          { user_meta: { last_name: { _ilike: $search } } }
        ]
      }
    ) {
      id
      password
      user_num
      user_meta {
        first_name
        last_name
        occupation
        email
      }
      performer {
        name
        user_id
      }
    }
  }
`;

const GET_MEMBER = gql`
  query MyQuery($performer_id: uuid!) {
    app5s_userPerformer(id: $performer_id) {
      user_id
      account {
        password
      }
      email
      name
      id
      position
      user {
        meta {
          last_name
          first_name
          email
          occupation
          user_id
        }
      }
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation MyQuery(
    $user_id: uuid!
    $email: String!
    $name: String!
    $username: citext!
    $occupation: String!
    $last_name: String!
    $first_name: String!
  ) {
    update_user_meta_sat(
      where: { user_id: { _eq: $user_id } }
      _set: {
        email: $email
        first_name: $first_name
        last_name: $last_name
        occupation: $occupation
      }
    ) {
      affected_rows
    }
  }
`;

/*
app5s_updateUserPerformers(
      where: { user_id: { _eq: $user_id } }
      _set: { name: $name, email: $email }
    ) {
      affected_rows
    }

    update_user_auth_sat(
      where: { user_id: { _eq: $user_id } }
      _set: { username: $username }
    ) {
      affected_rows
    }
*/

export const UPDATE_PARTNER = gql`
  mutation MyQuery(
    $partner_id: Int!
    $_set: app_5steps_partners_set_input = {}
  ) {
    update_app_5steps_partners_by_pk(
      pk_columns: { id: $partner_id }
      _set: $_set
    ) {
      id
    }
  }
`;

export const UPDATE_CITIES = gql`
  mutation updateCities(
    $partner_id: Int!
    $cities: [app_5steps_partners_to_users_insert_input!]!
    $is_global: Boolean!
  ) {
    delete_app_5steps_partners_to_users(
      where: { partner_id: { _eq: $partner_id } }
    ) {
      affected_rows
    }

    update_app_5steps_partners_by_pk(
      pk_columns: { id: $partner_id }
      _set: { is_global: $is_global }
    ) {
      id
    }

    insert_app_5steps_partners_to_users(objects: $cities) {
      affected_rows
    }
  }
`;

export const ADD_PARTNER = gql`
  mutation addPartner($partner: app_5steps_partners_insert_input!) {
    insert_app_5steps_partners_one(object: $partner) {
      id
    }
  }
`;

export const REMOVE_PARTNER = gql`
  mutation removePartner($partner_id: Int!) {
    delete_app_5steps_partners(where: { id: { _eq: $partner_id } }) {
      affected_rows
    }
    delete_app_5steps_partners_to_users(
      where: { partner_id: { _eq: $partner_id } }
    ) {
      affected_rows
    }
  }
`;

export const GET_PARTNER_FORM = gql`
  query MyQuery($partner_id: Int!) {
    app_5steps_partners(where: { id: { _eq: $partner_id } }) {
      title
      interest
      is_global
      fio
      comment
      id
      mail
      contacts
    }
    app_5steps_partners_to_users(where: { partner_id: { _eq: $partner_id } }) {
      id
      partner_id
      user_id
      user {
        first_name
        last_name
      }
    }
  }
`;

export const GET_PARTNERS = gql`
  query getPartners($search: String) {
    app_5steps_partners(
      where: {
        _or: [
          { title: { _ilike: $search } }
          { region_text: { _ilike: $search } }
          { fio: { _ilike: $search } }
          { mail: { _ilike: $search } }
          { interest: { _ilike: $search } }
          { contacts: { _ilike: $search } }
          { comment: { _ilike: $search } }
          { city_text: { _ilike: $search } }
        ]
      }
      order_by: { cr: desc_nulls_last }
    ) {
      title
      region_text
      mail
      is_global
      interest
      fio
      id
      dateput
      contacts
      comment
      city_text
      users_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_PARTNER = gql`
  query getPartner($partner_id: Int = 10) {
    app_5steps_partners(where: { id: { _eq: $partner_id } }) {
      users {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

export const GET_CITY = gql`
  query getPartner($partner_id: Int = 10) {
    app_5steps_partners(where: { id: { _eq: $partner_id } }) {
      users {
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

const GET_EXECUTORS = gql`
  query getMembers($search: String) {
    app5s_userPerformers(
      where: {
        _or: [
          { user: { meta: { first_name: { _ilike: $search } } } }
          { user: { meta: { last_name: { _ilike: $search } } } }
        ]
      }
    ) {
      user_id
      cr
      user {
        meta {
          email
          first_name
          last_name
        }
      }
      id
      email
      name
      ordering
      position
    }
  }
`;

const GET_PROJECTSKB = gql`
  query getProjectsKB($search: String) {
    app5s_userProjectsSelection(
      where: {
        _or: [
          { user: { meta: { first_name: { _ilike: $search } } } }
          { user: { meta: { last_name: { _ilike: $search } } } }
        ]
      }
    ) {
      project_id
      user_id
      cr
      project {
        step
        key
        is_priority
        owner_user_id
      }
      user {
        meta {
          email
          first_name
          last_name
        }
      }
    }
  }
`;

const GET_ALL_PROJECTS = gql`
  query getAllProjects {
    app5s_projects(where: { is_approved: { _eq: true } }) {
      id
      step
      owner_user_id
      meta {
        title
      }
    }
  }
`;

const GET_CITIZENS_IDEAS = gql`
  query getCitizensIdeas {
    chats(
      where: {
        bot_id: { _eq: "a5de15c0-5f72-7001-5e0f-8871d4c6cdcc" }
        bot: {
          project_link: {
            project_id: { _eq: "e5f39eb8-f84f-42e9-afda-cde8271ddbd7" }
          }
        }
      }
    ) {
      id
      cr
      dialogs {
        id
        message
      }
    }

    dialog_bot(where: { id: { _eq: "a5de15c0-5f72-7001-5e0f-8871d4c6cdcc" } }) {
      id
      voc_link {
        voc {
          nodes {
            answers {
              name
              ordering
            }
            key
            text
          }
        }
      }
    }
  }
`;

const GET_PROJECTS_CITIES = gql`
  query getProjectsCities($search: String) {
    app5s_userProjects(
      where: {
        owner_user_id: { _is_null: false }
        _or: [
          { user: { meta: { first_name: { _ilike: $search } } } }
          { user: { meta: { last_name: { _ilike: $search } } } }
        ]
      }
      distinct_on: id
    ) {
      id
      owner_user_id
      is_approved
      is_rejected
      step
      cr
      meta {
        title
        about
        about_actual
        about_crowdsourcing
        about_finance
        project_id
        file
        file1
      }
      user_id
      user {
        meta {
          email
          first_name
          last_name
        }
      }
    }
  }
`;

const UPDATE_APPROVE_STATUS = gql`
  mutation CreateDocsMutation(
    $_eq: uuid!
    $is_approved: Boolean!
    $is_rejected: Boolean!
  ) {
    app5s_updateProjects(
      where: { id: { _eq: $_eq } }
      _set: { is_approved: $is_approved, is_rejected: $is_rejected }
    ) {
      affected_rows
      returning {
        id
        is_approved
        is_rejected
      }
    }
  }
`;

const GET_USERDOCS = gql`
  query getUserDocs($search: String) {
    app5s_userDocs(
      where: {
        checkpoint: { _eq: 0 }
        file: { _is_null: false }
        _or: [
          { user: { meta: { first_name: { _ilike: $search } } } }
          { user: { meta: { last_name: { _ilike: $search } } } }
        ]
      }
    ) {
      checkpoint
      cr
      file
      user_id
      user {
        meta {
          email
          first_name
          last_name
        }
      }
    }
  }
`;

const GET_INTERESTS = gql`
  query getUserDocs($search: String) {
    app5s_userDocs(
      where: {
        checkpoint: { _eq: 4 }
        file: { _is_null: false }
        _or: [
          { user: { meta: { first_name: { _ilike: $search } } } }
          { user: { meta: { last_name: { _ilike: $search } } } }
        ]
      }
    ) {
      checkpoint
      cr
      file
      user_id
      user {
        meta {
          email
          first_name
          last_name
        }
      }
    }
  }
`;

const GET_USERDOCSPOINTS = gql`
  query getUserDocs($search: String) {
    app5s_userDocs(
      where: {
        checkpoint: { _neq: 0 }
        _or: [
          { user: { meta: { first_name: { _ilike: $search } } } }
          { user: { meta: { last_name: { _ilike: $search } } } }
        ]
      }
    ) {
      checkpoint
      cr
      file
      user_id
      user {
        meta {
          email
          first_name
          last_name
        }
      }
    }
  }
`;

export const makeIdeaQuery = gql`
  mutation makeIdeaQuery(
    $id: uuid!
    $bot_id: uuid!
    $dialogs: [dialog_chat_dialog_sat_insert_input!]!
  ) {
    insert_dialog_chat_hub_one(object: { id: $id, bot_id: $bot_id }) {
      id
    }

    insert_dialog_chat_dialog_sat(objects: $dialogs) {
      affected_rows
    }
  }
`;

export {
  GET_MEMBER,
  GET_MEMBERS,
  GET_EXECUTORS,
  GET_PROJECTSKB,
  GET_PROJECTS_CITIES,
  GET_ALL_PROJECTS,
  GET_USERDOCS,
  GET_USERDOCSPOINTS,
  UPDATE_APPROVE_STATUS,
  GET_CITIZENS_IDEAS,
  GET_INTERESTS,
};
