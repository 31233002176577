/**
 * Styles
 */
import "./style.scss";
import styled from "styled-components";

/**
 * External Dependencies
 */
import React, { Component, Fragment } from "react";
import classnames from "classnames/dedupe";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Typography } from "antd";

/**
 * Internal Dependencies
 */
import "../../common-assets/js/yaybar/yaybar";
import { initPluginYaybar } from "../../common-assets/js/rootui-parts/initPluginYaybar";
import Icon from "../icon";

import { authLogout } from "../../actions/auth";

window.RootUI.initPluginYaybar = initPluginYaybar;

const { Link } = Typography;

const YaybarWrapper = styled.div`
  && .yay-wrap-menu {
    background: #2d2d2d;
  }

  &&& .yaybar-wrap ul > li {
    height: 40px;
    display: flex;

    &:hover {
      & .yay-icon,
      & .yay-icon * {
        color: white;
      }
    }

    & * {
      font-size: 11px;
    }

    & a {
      padding: 9px 0 9px 6px;
    }
  }

  &&& .yaybar-wrap ul > li > a > span:not([class]) {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
  }

  body .yaybar.yay-hide-to-small .yaybar-wrap > ul > li > a > span {
    display: none !important;
  }

  &&& .yay-item-active {
    background: #101010;
  }

  &&&& .yaybar-wrap ul > li > a > .rui-yaybar-circle {
    top: auto !important;
  }

  &&& .yay-item-active span:not([class]) {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 15px;
  }

  &&& .yay-item-active .yay-icon {
    color: rgba(255, 255, 255, 1) !important;
  }
`;

/**
 * Component
 */
const ConditionalWrapper = ({ condition, wrapper, defaultWrapper, children }) =>
  condition ? wrapper(children) : defaultWrapper(children);

class PageYaybar extends Component {
  constructor(props) {
    super(props);

    this.renderSubmenus = this.renderSubmenus.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    window.RootUI.initPluginYaybar();
  }

  logOut() {
    const { authLogout } = this.props;

    authLogout();
  }

  renderSubmenus(nav, returnObject = false) {
    let thereIsActive = false;

    const result = Object.keys(nav).map((url) => {
      if (
        !(
          url === "/map" ||
          (url === "/project/dialogs" &&
            this.props.currentProjectId ===
              "963ad36f-271c-1066-ffa1-dacf25855f0d") ||
          (url === "/help" &&
            this.props.currentProjectId ===
              "963ad36f-271c-1066-ffa1-dacf25855f0d") ||
          (url === "/help-baykalsk" &&
            this.props.currentProjectId !==
              "963ad36f-271c-1066-ffa1-dacf25855f0d")
        )
      ) {
        const data = nav[url];
        //const isActive = window.location.hash === `#${url}`;
        const isActive = window.location.pathname === `${url}`;
        let isOpened = false;

        if (isActive) {
          thereIsActive = true;
        }

        let sub = "";
        if (data.sub) {
          const subData = this.renderSubmenus(data.sub, true);

          sub = (
            <ul className="yay-submenu dropdown-triangle">{subData.content}</ul>
          );

          if (subData.thereIsActive) {
            isOpened = true;
            thereIsActive = true;
          }
        }

        const { externalLink } = { ...data };
        const className = data.sub ? "yay-sub-toggle" : "";

        return (
          <Fragment key={`${url}-${data.name}`}>
            {data.label ? <li className="yay-label">{data.label}</li> : ""}
            <li
              className={classnames({
                "yay-item-active": isActive,
                "yay-submenu-open": isOpened,
              })}
            >
              {data.name ? (
                <ConditionalWrapper
                  condition={externalLink}
                  wrapper={(children) => (
                    <Link
                      {...{ className }}
                      href={externalLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {children}
                    </Link>
                  )}
                  defaultWrapper={(children) => (
                    <NavLink {...{ className }} to={data.sub ? "#" : url}>
                      {children}
                    </NavLink>
                  )}
                >
                  <>
                    {data.icon ? (
                      <>
                        <span className="yay-icon">{data.icon}</span>
                        <span>{data.name}</span>
                        <span className="rui-yaybar-circle" />
                      </>
                    ) : (
                      data.name
                    )}
                    {data.sub ? (
                      <span className="yay-icon-collapse">
                        <Icon
                          name="chevron-right"
                          strokeWidth="1"
                          className="rui-icon-collapse"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </>
                </ConditionalWrapper>
              ) : (
                ""
              )}
              {sub}
            </li>
          </Fragment>
        );
      }
    });

    if (returnObject) {
      return {
        content: result,
        thereIsActive,
      };
    }

    return result;
  }

  render() {
    const { settings } = this.props;
    const sidebar_type = "5steps";

    let nav_bar;

    switch (sidebar_type) {
      case "main":
        nav_bar = settings.navigation_sidebar;
        break;
      case "5steps":
        nav_bar = settings.navigation_5steps_sidebar;
        break;
    }

    return (
      <>
        <YaybarWrapper
          className={classnames(
            "yaybar rui-yaybar yay-hide-to-small yay-gestures",
            settings.sidebar_dark && !settings.night_mode
              ? "rui-yaybar-dark"
              : "",
            settings.sidebar_static ? "yay-static" : "",
            settings.sidebar_effect ? `yay-${settings.sidebar_effect}` : ""
          )}
        >
          <div className="yay-wrap-menu">
            <div className="yaybar-wrap">
              <ul>
                {this.renderSubmenus(nav_bar)}

                <li className="yay-label"></li>
                <li>
                  <NavLink to="#" onClick={this.logOut}>
                    <span className="yay-icon">
                      <Icon name="log-out" />
                    </span>
                    <span>Выход</span>
                    <span className="rui-yaybar-circle" />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          {/*
                    <div className="rui-yaybar-icons">
                        <Row className="no-gutters justify-content-around">
                            <Col xs="auto">
                                <Link to={ settings.home_url } className="btn btn-custom-round">
                                    <Icon name="settings" />
                                </Link>
                            </Col>
                            <Col xs="auto">
                                <Link to={ settings.home_url } className="btn btn-custom-round">
                                    <Icon name="bell" />
                                </Link>
                            </Col>
                            <Col xs="auto" className="d-flex mt-3">
                                <Dropdown tag="li" direction="up" openOnHover showTriangle>
                                    <Dropdown.Toggle className="btn btn-custom-round">
                                        <Icon name="plus-circle" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu tag="ul" className="dropdown-menu nav" modifiers={ { offset: { offset: '-30px' }, flip: false } }>
                                        <li>
                                            <Link to="#" className="nav-link">
                                                <Icon name="plus-circle" />
                                                <span>Create new Post</span>
                                                <span className="rui-nav-circle" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="nav-link">
                                                <Icon name="book" />
                                                <span>Project</span>
                                                <span className="rui-nav-circle" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="nav-link">
                                                <Icon name="message-circle" />
                                                <span>Message</span>
                                                <span className="rui-nav-circle" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="nav-link">
                                                <Icon name="mail" />
                                                <span>Mail</span>
                                                <span className="rui-nav-circle" />
                                            </Link>
                                        </li>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col xs="auto">
                                <Link to={ settings.home_url } className="btn btn-custom-round">
                                    <Icon name="clock" />
                                </Link>
                            </Col>
                            <Col xs="auto">
                                <Link to={ settings.home_url } className="btn btn-custom-round">
                                    <Icon name="heart" />
                                </Link>
                            </Col>
                        </Row>
                    </div>
*/}
        </YaybarWrapper>
        <div className="rui-yaybar-bg" />
      </>
    );
  }
}

export default connect(
  ({ app, settings }) => ({
    app,
    settings,
    currentProject: app.currentProject,
    currentProjectId: app.currentProjectId,
  }),
  { authLogout }
)(PageYaybar);
