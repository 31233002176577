import React, { useEffect, useMemo, useState } from "react";

import { GET_CITIZENS_IDEAS } from "../../../../../queries/5-steps";
import makeQuery from "../components/query";
import styled from "styled-components";

import columns from "./columns";
import PageWrapper from "../index";
import { CustomTable } from "../index";
import { Row, Col, Typography, Space, Button } from "antd";

import columnsWire from "../wire/columns";
import SearchBar from "../components/search";

import { TotalCounter } from "../members";
import ExcelTable from "../components/excel";

import handleData from "./handleData";

import extraFormattedIdeas from "./extra-formatted-ideas";
import Repair from "./repair";

import citiesMeta from "./cities-meta.json";
import updatedCities from "./updatedCities.json";
import useStatusStore from "../../../../../store/status-store";
import makeMutation from "../components/mutation";
import { makeIdeaQuery } from "../../../../../queries/5-steps";
import { v4 as uuidv4 } from "uuid";

const MoreRow = styled.div`
  &&&&& * {
    font-size: 14px;
  }

  display: flex;
`;

const { Paragraph, Title } = Typography;

const CitizensIdeasPage = () => {
  const search = useStatusStore(({ search }) => search);
  const [requiredCity, setRequiredCity] = useState(null);

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (search) {
      const words = search.split(" ");

      let foundCityIndex = null;
      updatedCities.map(({ name }, i) => {
        let found = false;
        words.map((word) => {
          if (name && name.toLowerCase().includes(word.toLowerCase())) {
            found = true;
          } else {
            found = false;
          }
        });

        if (found) foundCityIndex = i;
      });

      if (foundCityIndex) {
        setRequiredCity(foundCityIndex);
      } else {
        setRequiredCity(9999999);
      }
    } else {
      setRequiredCity(null);
    }
  }, [search]);

  const [getCitizenIdeas, { data, loading }] = makeQuery(GET_CITIZENS_IDEAS);

  useEffect(() => {
    getCitizenIdeas({
      variables: {
        /*search*/
      },
    });
  }, [search]);

  const citizensIdeas = useMemo(() => {
    return handleData(data, requiredCity);
  }, [data, requiredCity]);

  const [writeIdeas, { data: w_data, loading: w_loading, error: w_error }] =
    makeMutation(makeIdeaQuery, setCount);

  if (w_error) console.log("w_error", w_error);
  if (w_data) console.log("w_data", w_data);

  const writeData = () => {
    /* extraFormattedIdeas
      .filter((_, i) => i > 7500 && i <= 8000)
      .map((item = {}) => {
        const { cr, id, dialogs = [] } = item;

        let dia = { dialogs: [] };
        dialogs.map((item = {}, i) => {
          const { message = {} } = item;

          dia.dialogs.push({ id: uuidv4(), message, chat_id: id });
        });

        dia.bot_id = "a5de15c0-5f72-7001-5e0f-8871d4c6cdcc";
        dia.id = id;

        writeIdeas({
          variables: dia,
        });
      }); */
  };

  return (
    <PageWrapper title="Идеи горожан">
      {/* <SearchBar {...{ setSearch }} noformat /> */}
      <div>Добавлено {count}</div>

      {citizensIdeas && (
        <TotalCounter>Всего идей горожан: {citizensIdeas.length} </TotalCounter>
      )}
      {citizensIdeas && <ExcelTable />}
      <br />
      <br />

      {null && <Button onClick={writeData}>Write Idea</Button>}

      {loading || !citizensIdeas ? (
        <CustomTable dataSource={Array(12).fill(1)} columns={columnsWire} />
      ) : (
        <CustomTable
          dataSource={citizensIdeas}
          columns={columns}
          rowKey={(record) => record.key}
          expandable={{
            expandedRowRender: (data = {}, __i) => {
              const keys = Object.keys(data);
              const nodes = keys.length > 0 ? data[keys[0]].nodes : {};

              const nodesList = Object.keys(nodes);

              return (
                <MoreRow key={`s:${__i}`}>
                  <div style={{ width: "calc(20% + 49px)" }}></div>

                  <Col style={{ width: "calc(100% - 20% - 50px)" }}>
                    {nodesList.map((key, _i) => {
                      const { name, answers } = nodes[key];
                      let { text, attachment, type } = data[key];

                      if (type === "variant") {
                        const { variant } = attachment;
                        const varSplit = variant.split(".");
                        const varIndex = varSplit[varSplit.length - 1];

                        let sel_answer = answers[varIndex];
                        if (key === "node0.0") {
                          sel_answer = updatedCities[varIndex];
                        }

                        text = sel_answer.name;
                      }

                      return (
                        <Col key={`cse:${_i}`} span={12}>
                          <Title level={5}>{name}</Title>
                          <Paragraph>{text ? text : "Отсутствует"}</Paragraph>
                        </Col>
                      );
                    })}
                  </Col>
                </MoreRow>
              );
            },
          }}
        />
      )}
    </PageWrapper>
  );
};

export default CitizensIdeasPage;
