import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { TotalCounter } from ".";
import { CustomTable } from "..";

import columns from "./columns";
import columnsWire from "../wire/columns";

import ExcelTable, { Btn } from "../components/excel";
import handleData from "./handleData";
import { Space } from "antd";

const NewUsers = ({
  getMembers,
  loading,
  setModal,
  membersData,
  search,
  setMember,
  memberLogId,
  setMemberLogId,
  addMemBtn,
}) => {
  useEffect(() => {
    getMembers({ variables: { search } });
  }, [search]);

  const members = useMemo(() => {
    if (membersData) {
      const { app5s_userAccounts: data = [] } = membersData;
      return data.map((item = {}) => {
        const { password, user_meta = {}, id } = item;
        const { first_name, last_name, occupation, email } = user_meta;

        let fullname = "";

        let noRegion = false;

        fullname += first_name ? first_name : "";

        return {
          id: id,
          user_id: id,
          email,
          fullname,
          occupation,
          cr: null,
          password,
        };
      });
    }
  }, [membersData]);

  return (
    <>
      <Space>
        {members && (
          <TotalCounter>Всего участников: {members.length} </TotalCounter>
        )}

        {members && (
          <Space size={0}>
            {/*  <ExcelTable /> */}
            {addMemBtn}
          </Space>
        )}
      </Space>

      <br />
      <br />

      {loading || !members ? (
        <CustomTable dataSource={Array(12).fill(1)} columns={columnsWire} />
      ) : (
        <CustomTable
          dataSource={members}
          columns={columns(setModal, setMember, setMemberLogId, true)}
        />
      )}
    </>
  );
};

export default NewUsers;
