export const emailValidationRules = [
  {
    required: true,
    message: "Поле является обязательным!",
  },
  {
    type: "email",
    message: "Пожалуйста введите корректный email!",
  },

  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || value.includes("@")) {
        return Promise.resolve();
      }
      return Promise.reject('Email must contain "@"');
    },
  }),
  ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || value.toLowerCase() === value) {
        return Promise.resolve();
      }
      return Promise.reject("Пожалуйста не используйте верхний регистр!");
    },
  }),
];

export const requiredRule = [
  {
    required: true,
    message: "Поле является обязательным!",
  },
];
