import { useEffect } from "react";
import mapboxgl from "!mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";

const useMapCreate = ({
  mapRef,
  zoom,
  center,
  pitch,
  mapPreloaded,
  onLoad = () => {},
}) => {
  useEffect(() => {
    if (mapPreloaded) {
      mapboxgl.accessToken =
        //"pk.eyJ1IjoibWFya2thYmllcnNraSIsImEiOiJja2lpa3N2c3QwaXVrMnltbHVzcXZ3dDU2In0.t_Lcd-0hPAJSk75HCJFw0g";
        "pk.eyJ1IjoiaXBha2hvbW92IiwiYSI6ImNrbXl1bHZtMDA3anIybm8zYW52Y202a3YifQ.vsCRi5VoeucI6IyRzDTZwA";

      const map = new mapboxgl.Map({
        container: mapRef.current,
        //style: "mapbox://styles/markkabierski/cl66ci4l0001f14ti5l7ukm1k",
        style: "mapbox://styles/ipakhomov/cl1kvdzpo000e14nw5i7qei1s",
        zoom: typeof zoom === "number" ? zoom : 2.4549661994141507,
        center: !center ? [98.10606143680025, 64.68612023074468] : center,
        antialias: true,
        projection: "mercator",
      });

      const language = new MapboxLanguage();
      map.addControl(language);

      /* Шаг 2: Настройка контента карты */
      map.on("load", () => {
        let labels = [
          "country-label",
          "state-label",
          "settlement-subdivision-label",
          "airport-label",
          "poi-label",
          "water-point-label",
          "water-line-label",
          "natural-point-label",
          "natural-line-label",
          "waterway-label",
          "road-label",
        ];

        labels.forEach((label) => {
          map.setLayoutProperty(label, "text-field", ["get", "name_ru"]);
        });

        onLoad(map);
      });

      return () => {
        map.remove();
      };
    }
  }, [mapPreloaded]);
};

export default useMapCreate;
