const handleData = (data, handleApproving) => {
  if (data) {
    const { app5s_userProjects = [] } = data;

    let _projectsCities = [];

    app5s_userProjects.map((item = {}, key) => {
      const {
        user_id,
        id: projectId,
        user = {},
        is_approved,
        is_rejected,
        step,
        cr,
      } = item;

      const { meta: userMeta = [] } = user;
      const {
        email: city_email,
        first_name,
        last_name,
      } = userMeta.length > 0 ? userMeta[0] : [];

      let city_name = "";
      city_name += first_name ? first_name : "";
      city_name += last_name ? ` ${last_name}` : "";

      _projectsCities[user_id] = {
        city_name,
        city_email,
        city_id: user_id,
      };

      const { meta = {} } = item;

      _projectsCities.push({
        ..._projectsCities[user_id],
        ...meta,
        is_approved,
        is_rejected,
        id: projectId,
        handleApproving,
        key,
        cr,
        step,
      });
    });

    return _projectsCities.sort((i1 = {}, i2 = {}) => {
      const { cr: cr1 } = i1;
      const { cr: cr2 } = i2;

      if (cr2 < cr1) {
        return -1;
      }
      if (cr2 > cr1) {
        return 1;
      }
      return 0;
    });
  }

  return null;
};

export const handleDataA = (data) => {
  if (data) {
    const { app5s_userProjects = [] } = data;

    let _projectsCities = [];

    app5s_userProjects.map((item = {}, key) => {
      const {
        user_id,
        id: projectId,
        user = {},
        is_approved,
        is_rejected,
        step,
        cr,
      } = item;

      const { meta: userMeta = [] } = user;
      const {
        email: city_email,
        first_name,
        last_name,
      } = userMeta.length > 0 ? userMeta[0] : [];

      let city_name = "";
      city_name += first_name ? first_name : "";
      city_name += last_name ? ` ${last_name}` : "";

      const { meta = {} } = item;

      if (!is_rejected) {
        _projectsCities.push({
          city_name,
          city_email,
          city_id: user_id,
          ..._projectsCities[user_id],
          ...meta,
          is_rejected,
          is_approved,
          id: projectId,
          key,
          cr,
          step,
        });
      }
    });

    return _projectsCities.sort((i1 = {}, i2 = {}) => {
      const { cr: cr1 } = i1;
      const { cr: cr2 } = i2;

      if (cr2 < cr1) {
        return -1;
      }
      if (cr2 > cr1) {
        return 1;
      }
      return 0;
    });
  }

  return;
};

export default handleData;
