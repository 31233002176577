const handleData = (membersData, noRegion) => {
  if (membersData) {
    const { app5s_userPerformers = [] } = membersData;

    return app5s_userPerformers
      .map((userData) => {
        const { user_id, user = {}, cr, id, account = {} } = userData;
        const { password } = account ? account : {};
        const { meta = [] } = user;

        const { email, first_name, last_name, occupation } =
          meta.length > 0 ? meta[0] : [];

        let fullname = "";

        fullname += first_name ? first_name : "";
        if (!noRegion) {
          fullname += last_name ? ` ${last_name}` : "";
        }

        return {
          id,
          user_id,
          email,
          fullname,
          occupation,
          cr,
          password,
        };
      })
      .sort((i1 = {}, i2 = {}) => {
        const { cr: cr1 } = i1;
        const { cr: cr2 } = i2;

        if (cr2 < cr1) {
          return -1;
        }
        if (cr2 > cr1) {
          return 1;
        }
        return 0;
      });
  }

  return;
};

export default handleData;
