import React, { useEffect, useMemo, useState } from "react";

import {
  GET_PROJECTS_CITIES,
  UPDATE_APPROVE_STATUS,
} from "../../../../../queries/5-steps";
import makeQuery from "../components/query";
import styled from "styled-components";

import columns from "./columns";
import PageWrapper from "../index";
import { CustomTable } from "../index";
import { Row, Col, Typography, Space } from "antd";
import makeMutation from "../components/mutation";

import columnsWire from "../wire/columns";
import SearchBar from "../components/search";

import { TotalCounter } from "../members";
import ExcelTable from "../components/excel";

import handleData from "./handleData";
import useStatusStore from "../../../../../store/status-store";

const MoreRow = styled.div`
  &&&&& * {
    font-size: 14px;
  }

  display: flex;
`;

const { Paragraph, Title } = Typography;

const ProjectsCitiesPage = () => {
  const search = useStatusStore(({ search }) => search);

  const [getProjectKB, { data, loading }] = makeQuery(GET_PROJECTS_CITIES);
  const [updateStatus, { data: status_data, error: status_error }] =
    makeMutation(UPDATE_APPROVE_STATUS, {
      refetchQueries: [{ query: GET_PROJECTS_CITIES }, "getProjectsCities"],
    });

  useEffect(() => {
    getProjectKB({ variables: { search } });
  }, [search]);

  const handleApproving = (id, status) => {
    let is_approved = false;
    let is_rejected = false;

    if (status === "approved") {
      is_approved = true;
    } else if (status === "rejected") {
      is_rejected = true;
    }

    return updateStatus({ variables: { _eq: id, is_approved, is_rejected } });
  };

  const [updatedData, setUpdatedData] = useState([]);

  const projectsCities = useMemo(() => {
    let formattedData = handleData(data, handleApproving);

    return formattedData;
  }, [data]);

  useEffect(() => {
    if (projectsCities) {
      setUpdatedData(projectsCities);
    }
  }, [projectsCities]);

  useEffect(() => {
    if (status_data) {
      setUpdatedData((state) => {
        if (state && state.length > 0) {
          let updState = [...state];

          if (status_data) {
            const { app5s_updateProjects = {} } = status_data;
            const { returning = [] } = app5s_updateProjects;

            if (returning.length > 0) {
              const [returnItem = {}] = returning;
              const {
                id: r_id,
                is_approved: r_is_approved,
                is_rejected: r_is_rejected,
              } = returnItem;

              updState = updState.map((item = {}) => {
                const { id } = item;

                if (id !== r_id) return item;

                let updItem = { ...item };
                updItem.is_approved = r_is_approved;
                updItem.is_rejected = r_is_rejected;

                return updItem;
              });
            }
          }

          return updState;
        } else {
          return state;
        }
      });
    }
  }, [status_data]);

  return (
    <PageWrapper title="Проекты городов">
      {/* <SearchBar {...{ setSearch }} /> */}

      {projectsCities && (
        <TotalCounter>
          Всего проектов по городам: {projectsCities.length}{" "}
        </TotalCounter>
      )}
      {projectsCities && <ExcelTable />}
      <br />
      <br />

      {loading || !projectsCities ? (
        <CustomTable dataSource={Array(12).fill(1)} columns={columnsWire} />
      ) : (
        <CustomTable
          dataSource={updatedData}
          columns={columns}
          expandable={{
            expandedRowRender: ({
              about,
              about_actual,
              about_finance,
              about_crowdsourcing,
              file,
              file1 = [],
              step,
            }) => {
              return (
                <MoreRow>
                  <div style={{ width: "calc(20% + 49px)" }}></div>

                  <Col style={{ width: "calc(100% - 20% - 50px)" }}>
                    <Row gutter={[12, 12]}>
                      <Col span={12}>
                        <Title level={5}>Описание</Title>
                        <Paragraph>{about}</Paragraph>
                      </Col>

                      <Col span={12}>
                        <Title level={5}>Актуальность</Title>
                        <Paragraph>{about_actual}</Paragraph>
                      </Col>
                    </Row>

                    <Row gutter={[12, 12]}>
                      <Col span={12}>
                        <Title level={5}>Источники финансирования</Title>
                        <Paragraph>{about_finance}</Paragraph>
                      </Col>

                      <Col span={12}>
                        <Title level={5}>Вовлечение горожан</Title>
                        <Paragraph>{about_crowdsourcing}</Paragraph>
                      </Col>
                    </Row>

                    <Row gutter={[12, 12]}>
                      <Col span={12}>
                        <Title level={5}>Дополнительные материалы</Title>
                        <Paragraph>
                          {file1 && file1.length > 0 ? (
                            <Space direction="vertical">
                              {file1.map((file) => (
                                <a href={file} target="_blank">
                                  Открыть файл
                                </a>
                              ))}
                            </Space>
                          ) : (
                            "отсутствуют"
                          )}
                        </Paragraph>
                      </Col>

                      <Col span={12}>
                        <Title level={5}>Шаг</Title>
                        <Paragraph>{step}</Paragraph>
                      </Col>
                    </Row>
                  </Col>
                </MoreRow>
              );
            },
          }}
        />
      )}
    </PageWrapper>
  );
};

export default ProjectsCitiesPage;
