import moment from "moment";

const columns = [
  {
    title: "Имя горожанина",
    dataIndex: "node0.3",
    render: (e) => {
      const { text } = e;
      return text ? text : "(Отсутствует)";
    },
    width: "20%",
  },

  {
    title: "Время",
    dataIndex: "cr",
    render: (e) => {
      if (moment().to(e) && moment().to(e) !== "Invalid date")
        e = moment(e).format("DD.MM.YYYY");

      return e ? e : "(Отсутствует)";
    },
    width: "10%",
  },
  {
    title: "Описание идеи",
    dataIndex: "node0.2",
    render: (e) => {
      const { text } = e;
      return text ? text : "(Отсутствует)";
    },
    width: "80%",
  },
];

export default columns;
