import React, { useMemo, useState } from "react";

import { CustomTable } from "../index";

import columns from "./columns-vis";
import PageWrapper from "../index";
import makeQuery from "../components/query";

import { GET_USERDOCSPOINTS } from "../../../../../queries/5-steps";
import { useEffect } from "react";
import { check } from "prettier";
import columnsWire from "../wire/columns";
import SearchBar from "../components/search";

import { TotalCounter } from "../members";
import ExcelTable from "../components/excel";

import handleData from "./handleData";
import useStatusStore from "../../../../../store/status-store";

const ReportsPage = () => {
  const search = useStatusStore(({ search }) => search);

  const [getReports, { data, loading }] = makeQuery(GET_USERDOCSPOINTS);

  useEffect(() => {
    getReports({ variables: { search } });
  }, [search]);

  let reports = useMemo(() => {
    return handleData(data);
  }, [data]);
  return (
    <PageWrapper title="Отчеты">
      {/* <SearchBar {...{ setSearch }} /> */}

      {reports && (
        <TotalCounter>Всего записей по городам: {reports.length} </TotalCounter>
      )}
      {reports && <ExcelTable />}
      <br />
      <br />

      {loading ? (
        <CustomTable dataSource={Array(12).fill(1)} columns={columnsWire} />
      ) : reports ? (
        <CustomTable dataSource={reports} columns={columns} />
      ) : (
        <></>
      )}
    </PageWrapper>
  );
};

export default ReportsPage;
