const handleData = (data) => {
  if (data) {
    const { app5s_userDocs = [] } = data;

    let _docs = [];

    app5s_userDocs.map((item) => {
      const { user_id, user, file, checkpoint, cr } = item;

      if (!_docs[user_id]) {
        const { meta = [] } = user;
        const { first_name, last_name } = meta.length > 0 ? meta[0] : [];

        let city_name = "";
        city_name += first_name ? first_name : "";
        city_name += last_name ? ` ${last_name}` : "";

        _docs[user_id] = { city_name, city_id: user_id };
      }

      if (checkpoint === 1) {
        _docs[user_id]["point1"] = file;
        _docs[user_id]["cr1"] = cr;
      }

      if (checkpoint === 2) {
        _docs[user_id]["point2"] = file;
        _docs[user_id]["cr2"] = cr;
      }

      if (checkpoint === 3) {
        _docs[user_id]["point3"] = file;
        _docs[user_id]["cr3"] = cr;
      }
    });

    let result = Object.keys(_docs).map((key) => _docs[key]);

    result = result.filter((item = {}) => {
      const { point1, point2, point3 } = item;

      const isString = (a) => typeof a === "string";

      return isString(point1) || isString(point2) || isString(point3);
    });

    return result.sort((i1 = {}, i2 = {}) => {
      const { cr: cr1 } = i1;
      const { cr: cr2 } = i2;

      if (cr2 < cr1) {
        return -1;
      }
      if (cr2 > cr1) {
        return 1;
      }
      return 0;
    });
  }

  return [];
};

export default handleData;
