import React from "react";
import moment from "moment";
import { DwnlBtn, None } from "./__styles";
const Moment = require("moment");

export const timeFormat = "dd, MMM Do YYYY, HH:mm:ss";

export const open = (e) => {
  window.open(e, "_blank");
};

const columns = [
  {
    title: "Город",
    dataIndex: "city_name",
    render: (e) => e,
  },
  {
    title: "Отчет по контрольной точке 1",

    dataIndex: "cr1",
    render: (e, { cr1, point1 }) =>
      e ? (
        <>
          <DwnlBtn onClick={() => open(point1)}>
            <a>Скачать файл</a>
            <span>{moment(e).format(timeFormat)}</span>
          </DwnlBtn>
        </>
      ) : (
        <None>(Отсутствует)</None>
      ),
    //sorter: (a, b, c, d) => new Moment(a.c1) - new Moment(b.c1),
    defaultSortOrder: "descend",
    sorter: (a, b) => new Date(b.cr1).getTime() - new Date(a.cr1).getTime(),
  },
  {
    title: "Отчет по контрольной точке 2",
    dataIndex: "point2",
    render: (e, { cr2 }) =>
      e ? (
        <>
          <DwnlBtn onClick={() => open(e)}>
            <a>Скачать файл</a>
            <span>{moment(cr2).format(timeFormat)}</span>
          </DwnlBtn>
        </>
      ) : (
        <None>(Отсутствует)</None>
      ),
    sorter: (a, b) => new Date(b.cr2).getTime() - new Date(a.cr2).getTime(),
  },
  {
    title: "Отчет по контрольной точке 3",
    dataIndex: "point3",
    render: (e, { cr3 }) =>
      e ? (
        <>
          <DwnlBtn onClick={() => open(e)}>
            <a>Скачать файл</a>
            <span>{moment(cr3).format(timeFormat)}</span>
          </DwnlBtn>
        </>
      ) : (
        <None>(Отсутствует)</None>
      ),
    sorter: (a, b) => new Date(b.cr3).getTime() - new Date(a.cr3).getTime(),
  },
];

export default columns;
