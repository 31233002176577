import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { TotalCounter } from ".";
import { CustomTable } from "..";

import columns from "./columns";
import columnsWire from "../wire/columns";

import ExcelTable, { Btn } from "../components/excel";
import handleData from "./handleData";
import { Space } from "antd";

const ActiveMembers = ({
  getMembers,
  loading,
  setModal,
  membersData,
  search,
  setMember,
  memberLogId,
  setMemberLogId,
  addMemBtn,
}) => {
  useEffect(() => {
    getMembers({ variables: { search } });
  }, [search]);

  const members = useMemo(() => {
    const result = handleData(membersData);
    return result;
  }, [membersData]);

  return (
    <>
      <Space>
        {members && (
          <TotalCounter>Всего участников: {members.length} </TotalCounter>
        )}

        {members && (
          <Space size={0}>
            <ExcelTable />
            {addMemBtn}
          </Space>
        )}
      </Space>

      <br />
      <br />

      {loading || !members ? (
        <CustomTable dataSource={Array(12).fill(1)} columns={columnsWire} />
      ) : (
        <CustomTable
          dataSource={members}
          columns={columns(setModal, setMember, setMemberLogId)}
        />
      )}
    </>
  );
};

export default ActiveMembers;
