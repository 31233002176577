import styled from "styled-components";

export const DwnlBtn = styled.div`
  transform: translateX(-12px);

  width: max-content;
  display: flex;
  flex-direction: column;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  border-radius: 10px;
  cursor: pointer;

  padding: 6px 12px;

  && > * + * {
    margin-top: 6px;
  }

  & span {
    font-size: 9px;
  }

  & a,
  & a:hover {
    font-size: 14px;
    font-weight: 600;
    color: #5c5ef9;
  }
`;

export const None = styled.div`
  opacity: 0.5;
`;
