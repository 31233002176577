import extraFormattedIdeas from "./extra-formatted-ideas";
import moment from "moment";

const handleData = (data, requiredCity) => {
  if (data) {
    let { chats = [], dialog_bot = [] } = data;

    const { voc_link = {} } = dialog_bot.length > 0 ? dialog_bot[0] : {};
    const { voc = {} } = voc_link;
    const { nodes: _nodes = [] } = voc;

    let nodes = {};

    _nodes.map((item = {}) => {
      const { key, text, answers = [] } = item;

      nodes[key] = {
        name: text,
        answers,
      };
    });

    let _citizensIdeas = [];

    if (chats) {
      let _extraFormattedIdeas = [...extraFormattedIdeas].map((item = {}) => {
        const { cr, ...otherProps } = item;

        return { ...otherProps, cr };
      });

      console.log("_extraFormattedIdeas", _extraFormattedIdeas);

      _citizensIdeas = [...chats, ..._extraFormattedIdeas]
        .sort((item = {}, item2 = {}) => {
          const { cr: cr1 } = item;
          const { cr: cr2 } = item2;

          if (cr2 < cr1) {
            return -1;
          }
          if (cr2 > cr1) {
            return 1;
          }
          return 0;
        })
        .filter((item = {}) => {
          const { id } = item;
          const hiddenIds = [
            "8c3ef3b6-571c-7f7d-5d80-ae64cb45e97e",
            "8246a6b0-67f9-61e9-043e-062dcca6dc26",
            "82f27f6f-54e8-6340-e631-a3e6a5bd12e2",
            "ea9975f3-a862-c735-f206-96f74febd04e",
            "4164240f-5560-17e6-526e-61d67e41d615",
            "01c1bd9a-0235-39aa-49df-41026c6ad40f",
            "1a29eed5-4da4-75fc-d43c-d075c74b8af5",
            "94e7516b-d77b-9365-be57-b35bdbb6e9db",
          ];

          return !hiddenIds.includes(id);
        })
        .map((item = {}, i) => {
          const { dialogs = [], id: chat_id, cr } = item;

          let chatSummary = {};

          dialogs.map((row = {}) => {
            const { message } = row;

            const { attachment, node, text, type } = message;

            chatSummary[node] = {
              type,
              text,
              attachment,
              nodes,
            };
          });

          return { ...chatSummary, chat_id, cr, key: `rowI:${i}` };
        });

      if (requiredCity) {
        _citizensIdeas = _citizensIdeas.filter((item = {}) => {
          if (item["node0.0"]) {
            const node00 = item["node0.0"];
            const { attachment = {} } = node00 ? node00 : {};
            const { variant } = attachment;

            if (variant === `answer.0.0.${requiredCity}`) return true;
          }

          return false;
        });
      }
    }

    return _citizensIdeas;
  }
};

export const handleDataA = (data) => {
  if (data) {
    const { app5s_userProjects = [] } = data;

    let _projectsCities = [];

    app5s_userProjects.map((item = {}, key) => {
      const { user_id, id: projectId, user = {}, is_approved, step, cr } = item;

      const { meta: userMeta = [] } = user;
      const {
        email: city_email,
        first_name,
        last_name,
      } = userMeta.length > 0 ? userMeta[0] : [];

      let city_name = "";
      city_name += first_name ? first_name : "";
      city_name += last_name ? ` ${last_name}` : "";

      const { meta = {} } = item;

      _projectsCities.push({
        city_name,
        city_email,
        city_id: user_id,
        ..._projectsCities[user_id],
        ...meta,
        is_approved,
        id: projectId,
        key,
        cr,
        step,
      });
    });

    return _projectsCities.sort((i1 = {}, i2 = {}) => {
      const { cr: cr1 } = i1;
      const { cr: cr2 } = i2;

      if (cr2 < cr1) {
        return -1;
      }
      if (cr2 > cr1) {
        return 1;
      }
      return 0;
    });
  }

  return;
};

export default handleData;
