import mapboxgl from "mapbox-gl";

const handleRegionLayer = ({ map, mapGeom }) => {
  const r_source_name = `s:regions`;
  const r_layer_fill_name = `l:f:regions`;
  const r_layer_stroke_name = `l:s:regions`;

  if (mapGeom) {
    const { rustourism_hub_regions_geom: regions_hub = [] } = mapGeom;

    if (!map.getSource(r_source_name)) {
      const result = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: regions_hub.map((item = {}, i) => {
            const { region_id, geom = {}, regions_meta = {} } = item;

            const { name } = regions_meta;

            return {
              id: i,
              type: "Feature",
              geometry: geom,
              properties: { region_id, region_name: name },
            };
          }),
        },
      };

      map.addSource(r_source_name, result);
    }

    if (!map.getLayer(r_layer_fill_name)) {
      map.addLayer({
        id: r_layer_fill_name,
        type: "fill",
        source: r_source_name, // reference the data source
        layout: {},
        paint: {
          "fill-color": "#64666A", // blue color fill
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.6,
            0,
          ],
        },
      });
    }

    if (!map.getLayer(r_layer_stroke_name)) {
      map.addLayer({
        id: r_layer_stroke_name,
        type: "line",
        source: r_source_name,
        layout: {},
        paint: {
          "line-color": 'grey',
          "line-width": 1,
        },
      });
    }
  }
};

export default handleRegionLayer;
