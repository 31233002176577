import citiesData from "../members/extra-meta1.json";

const handleData = (data, allProjects, noRegion) => {
  if (data && allProjects) {
    const { app5s_userProjectsSelection = [] } = data;
    const { app5s_projects = [] } = allProjects;

    /* находим существующие проекты для каждого из шагов */
    const handleStepFiltering = (_step) =>
      app5s_projects
        .filter(({ step, owner_user_id }) => step === _step && !owner_user_id)
        .map(({ id, step, meta }) => {
          if (meta && meta?.title) {
            const { title = "" } = meta;
            return { project_id: id, step, title };
          }
        });

    const handleStepOwnFiltering = (_step) =>
      app5s_projects
        .filter(({ step, owner_user_id }) => step === _step && owner_user_id)
        .map(({ id, step, meta, owner_user_id }) => {
          const { title = "" } = meta;
          return { project_id: id, step, title, owner_user_id };
        });

    /* отфильтрованные по шагам все* проекты */
    const f1 = handleStepFiltering(1);
    const f2 = handleStepFiltering(2);
    const f3 = handleStepFiltering(3);
    const f4 = handleStepFiltering(4);
    const f5 = handleStepFiltering(5);

    /* отфильтрованные по шагам все* проекты */
    const fo1 = handleStepOwnFiltering(1);
    const fo2 = handleStepOwnFiltering(2);
    const fo3 = handleStepOwnFiltering(3);
    const fo4 = handleStepOwnFiltering(4);
    const fo5 = handleStepOwnFiltering(5);

    let _projectsKB = {};

    app5s_userProjectsSelection
      .filter((item = {}) => item && item.project)
      .map((item = {}) => {
        const { user_id, user = {}, cr } = item;

        if (!_projectsKB[user_id]) {
          const { meta = [] } = user;
          const {
            email: city_email,
            first_name,
            last_name,
          } = meta.length > 0 ? meta[0] : [];

          let city_name = "";
          city_name += first_name ? first_name : "";
          if (!noRegion) {
            city_name += last_name ? ` ${last_name}` : "";
          }

          if (city_email) {
            const findCity = citiesData.find(({ email: em }) => {
              return em.toLowerCase().includes(city_email.toLowerCase());
            });

            if (findCity) {
              const { name, region } = findCity;

              if (!noRegion) {
                city_name = `${name} ${region}`;
              } else {
                city_name = `${name}qqq${region}`;
              }
            }
          }

          _projectsKB[user_id] = {
            cr,
            city_name,
            city_email,
            city_id: user_id,
            step1: [],
            step2: [],
            step3: [],
            step4: [],
            step5: [],
            unused: {
              step1: [],
              step2: [],
              step3: [],
              step4: [],
              step5: [],
            },
            own: { step1: [], step2: [], step3: [], step4: [], step5: [] },
          };

          _projectsKB[user_id].own.step1 = [...fo1].filter(
            ({ owner_user_id }) => owner_user_id === user_id
          );
          _projectsKB[user_id].own.step2 = [...fo2].filter(
            ({ owner_user_id }) => owner_user_id === user_id
          );
          _projectsKB[user_id].own.step3 = [...fo3].filter(
            ({ owner_user_id }) => owner_user_id === user_id
          );
          _projectsKB[user_id].own.step4 = [...fo4].filter(
            ({ owner_user_id }) => owner_user_id === user_id
          );
          _projectsKB[user_id].own.step5 = [...fo5].filter(
            ({ owner_user_id }) => owner_user_id === user_id
          );
        }

        const { project_id, project = {} } = item;
        const { key, step, is_priority } = project;

        _projectsKB[user_id][`step${step}`].push({
          project_id,
          name: key,
          is_priority,
        });
      });

    const preResult = Object.keys(_projectsKB).map((key) => _projectsKB[key]);
    let result = preResult.map((item = {}) => {
      const {
        city_id,
        step1 = [],
        step2 = [],
        step3 = [],
        step4 = [],
        step5 = [],
      } = item;

      /* находим незадействованные проекты */
      const handleDif = (f_inherit = [], step_inherit = []) => {
        return f_inherit.filter((el) => {
          if (el?.project_id) {
            const id1 = el.project_id;
            return !step_inherit.some(({ project_id: id2 }) => id2 === id1);
          }
        });
      };

      const dif1 = handleDif(f1, step1);
      const dif2 = handleDif(f2, step2);
      const dif3 = handleDif(f3, step3);
      const dif4 = handleDif(f4, step4);
      const dif5 = handleDif(f5, step5);

      item.unused.step1 = [...dif1];
      item.unused.step2 = [...dif2];
      item.unused.step3 = [...dif3];
      item.unused.step4 = [...dif4];
      item.unused.step5 = [...dif5];

      return item;
    });

    const sortedResult = result.sort((i1 = {}, i2 = {}) => {
      const { cr: cr1 } = i1;
      const { cr: cr2 } = i2;

      if (cr2 < cr1) {
        return -1;
      }
      if (cr2 > cr1) {
        return 1;
      }
      return 0;
    });

    return sortedResult;
  }

  return;
};

export default handleData;
