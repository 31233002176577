const handleAttractionsLayer = ({ map }) => {
  map.setLayoutProperty("country-label", "text-field", ["get", `name_ru`]);
  map.setLayoutProperty("country-label", "visibility", "none");
  map.setLayoutProperty("state-label", "text-field", ["get", `name_ru`]);
  map.setLayoutProperty("state-label", "visibility", "none");
  map.setLayoutProperty("settlement-major-label", "text-field", [
    "get",
    `name_ru`,
  ]);
  map.setLayoutProperty("settlement-major-label", "visibility", "none");
  map.setLayoutProperty("settlement-minor-label", "text-field", [
    "get",
    `name_ru`,
  ]);
  map.setLayoutProperty("settlement-minor-label", "visibility", "none");

  if (!map.getSource("attractions")) {
    map.addSource("attractions", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
  }

  map.on("mousemove", () => {});

  if (!map.getLayer("l_attrs")) {
    map.addLayer({
      id: "l_attrs",
      type: "circle",
      source: "attractions",

      paint: {
        "circle-radius": {
          property: "size",
          base: 5,
          stops: [
            /* */
            [{ zoom: 3, value: 1 }, 4],

            /* */
            [{ zoom: 6, value: 1 }, 15],

            /* */
            [{ zoom: 20, value: 1 }, 15],
          ],
        },
        // Color circles by ethnicity, using a `match` expression.
        "circle-color": [
          "match",
          ["get", "hasProjects"],
          "has",
          "#5c5ef9",
          "none",
          "#ACACAC",
          "#ACACAC",
        ],
        "circle-stroke-color": "#000000",
        "circle-stroke-opacity": 0.9,
        "circle-stroke-width": 0.4,
      },
    });
  }

  if (map.getLayer("l_attrs")) {
    const l_attr_layer = map.getLayer("l_attrs");

    l_attr_layer.on("mousemove", (e) => {});
  }
};

export default handleAttractionsLayer;
