import { createGlobalStyle } from "styled-components";
//colors
const PRIMARY_COLOR = "#5C5EF9";
//const LIGHT_PRIMARY_RGB = "rgba(223, 228, 255, 1)";
const HEADING_COLOR = "#323232";
const TEXT_COLOR = "#171717";
const LIGHT_TEXT_COLOR = "#7C8496";
const ACCENT_COLOR = "#F95CA7";
const ERROR_COLOR = "#FF5449";

const GlobalStyle = createGlobalStyle`
  :root {

    --font-fallback: "Roboto", 'Helvetica Neue', Arial, sans-serif;
    --font-inter: Inter, 'Helvetica Neue', Arial, sans-serif;
    --font-roboto: Roboto, 'Helvetica Neue', Arial, sans-serif;

    --color-accent: ${ACCENT_COLOR};
    --color-primary: ${PRIMARY_COLOR};
    --color-light-primary: 223, 228, 255;
    --color-heading: ${HEADING_COLOR};
    --color-text: ${TEXT_COLOR};
    --color-light-text: ${LIGHT_TEXT_COLOR};
    --color-error: ${ERROR_COLOR};
    --box-shadow: 0px 0px 6px 2px rgba(157, 161, 255, 0.31);
    --box-shadow-gray: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
  }

  html, body, #__next {
    background:  #EEEEEE;
    height: 100%;
    font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;

    ::selection {
      background-color: rgba(var(--color-light-primary), 1);
    }
  }

  .ant-btn.ant-btn-primary {
    font-weight: 600;
  }

  && .os-scrollbar {
    display: none
  }

  &&&& .os-scrollbar-handle {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none; 
      scrollbar-width: none; 
    }
  }

  && .rui-yaybar-circle {
    display: none
  }

  .ant-btn.ant-btn-secondary {
    height: 36px;
    color: var(--color-text);
    border-color: var(--color-primary);
    background-color: white;
    border-radius: 5px;

    &:hover,
    &:active,
    &:focus {
      color: var(--color-text);
      background-color: white;
    }
  }

  .ant-checkbox-inner {
    border-radius: 4px;
  }

  .ant-btn.ant-btn-primary {
    height: 36px;
    color: white;
    //background-color: #ffba35;
    //border: 1px solid #ffba35;
    border-radius: 5px;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      color: white;
      //background-color: #ffba35;
      //border: 1px solid #ffba35;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: transparent !important;
    -webkit-animation-name: autofill !important;
    -webkit-animation-fill-mode: both !important;
  }

  .custom-antd-popover {
    background-color: white;
    width: 291px;
    border: 0;
    border-radius: 8px;
    font-size: 12px;
    padding: 2px;

    .ant-popover-arrow {
      opacity: 0;
    }

    .ant-popover-inner {
      background-color: white;
      border: 0;
      border-radius: 8px;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    color: var(--color-light-text);
    border: 1px solid rgba(var(--color-light-primary), 1);
  }

  .ant-select-arrow {
    color: rgba(var(--color-light-primary), 1);
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    span.anticon {
      vertical-align: 0;
    }
  }


`;

export default GlobalStyle;
