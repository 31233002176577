const handleData = (data) => {
  if (data) {
    const { app5s_userDocs = [] } = data;

    let docs = [];

    app5s_userDocs.map((item = {}) => {
      const { cr, file, user = {} } = item;

      const { meta: userMeta = [] } = user;
      const { first_name, last_name } = userMeta.length > 0 ? userMeta[0] : [];

      let city_name = "";
      city_name += first_name ? first_name : "";
      city_name += last_name ? ` ${last_name}` : "";

      docs = [
        ...docs,
        {
          city_name,
          cr,
          file,
        },
      ];
    });

    docs = docs.sort((i1 = {}, i2 = {}) => {
      const { cr: cr1 } = i1;
      const { cr: cr2 } = i2;

      if (cr2 < cr1) {
        return -1;
      }
      if (cr2 > cr1) {
        return 1;
      }
      return 0;
    });

    return docs;
  }

  return;
};

export default handleData;
