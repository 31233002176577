import React from "react";
import styled from "styled-components";
import ExcelTable from "./table";
import { Button } from "antd";
import { useState } from "react";

export const Btn = styled(Button)`
  && {
    border-radius: 10px;
    height: 30px;
    padding: 0 11px;
    margin-left: 14px;
  }

  &&[data-type="small"] {
    & {
      height: 24px;
    }
  }

  && * {
    font-size: 12px;
  }
`;

const ExcelSpace = () => {
  const [download, showDownload] = useState(false);

  return (
    <>
      {!download && (
        <Btn type="primary" onClick={() => showDownload(true)}>
          Выгрузить XLSX
        </Btn>
      )}

      {download && <ExcelTable />}
    </>
  );

  return;
};

export default ExcelSpace;
