import React from "react";
import moment from "moment";

const columns = [
  {
    title: "Город",
    dataIndex: "city_name",
    render: (e) => e,
  },
  {
    title: "Файл",
    dataIndex: "file",
    render: (e) => (
      <a href={e} target="_blank">
        Скачать файл
      </a>
    ),
  },
  {
    title: "Время предоставления файла",
    dataIndex: "cr",
    render: (e) => moment(e).format("dddd, MMMM Do YYYY, HH:mm:ss"),
  },
];

export default columns;
