import React, { useMemo, useEffect } from "react";
import ReactExport from "react-export-excel";
import styled from "styled-components";
import { Spin } from "antd";

import columnsMembers from "../../members/columns";
import columnsExecutors from "../../executors/columns";
import columnsProjectsKB from "../../projects-kb/columns";
import columnsPlans from "../../plans/columns";
import columnsReports from "../../reports/columns";

import moment from "moment";

import makeQuery from "../query";

import {
  GET_MEMBERS,
  GET_EXECUTORS,
  GET_PROJECTSKB,
  GET_ALL_PROJECTS,
  GET_PROJECTS_CITIES,
  GET_USERDOCS,
  GET_USERDOCSPOINTS,
  GET_CITIZENS_IDEAS,
  GET_INTERESTS,
} from "../../../../../../queries/5-steps";

import handleMembersData from "../../members/handleData";
import handleExecutorsData from "../../executors/handleData";
import handleProjectsKBData from "../../projects-kb/handleData";
import { handleDataA } from "../../projects-cities/handleData";
import handleDocsData from "../../plans/handleData";
import handleReportsData from "../../reports/handleData";
import handleCitizensData from "../../citizens-ideas/handleData";
import { handleGroup, handlePass, handleDate } from "./prepare";

import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import citiesMeta from "../../citizens-ideas/cities-meta.json";
import updatedCities from "../../citizens-ideas/updatedCities.json";

import { Btn } from ".";

const columnsProjectsCities = [
  {
    title: "Город",
    dataIndex: "city_name",
  },
  {
    title: "Название проекта",
    dataIndex: "title",
  },
  {
    title: "Одобрен",
    dataIndex: "is_approved",
  },
  {
    title: "Описание",
    dataIndex: "about",
  },
  {
    title: "Актуальность",
    dataIndex: "about_actual",
  },
  {
    title: "Источники финансирования",
    dataIndex: "about_finance",
  },
  {
    title: "Вовлечение горожан",
    dataIndex: "about_crowdsourcing",
  },
  {
    title: "Шаг",
    dataIndex: "step",
  },
  {
    title: "Файлы",
    dataIndex: "file1",
  },
];

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  padding: 1px 3px;
  max-width: max-content;
  margin-left: 14px;
  height: 36px;
`;

/* Excel */
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
/* */

const ExcelButton = ({
  dataMembers = [],
  dataExecutors = [],
  dataProjectsKB = [],
  projectsCities = [],
  userDocs = [],
  userInterests = [],
  reports = [],
  citizens = [],
  label = "Скачать",
  onlySection = false,
}) => {
  const location = useLocation();

  let definedSection = useMemo(() => {
    if (location) {
      const path = location.pathname;

      switch (path) {
        case "/project/members":
          return "members";
        case "/project/executors":
          return "executors";
        case "/project/projects-kb":
          return "projects-kb";
        case "/project/projects-cities":
          return "projects-cities";
        case "/project/plans":
          return "plans";
        case "/project/interests-consideration":
          return "interests";
        case "/project/reports":
          return "reports";
        case "/project/citizens-ideas":
          return "citizens-ideas";
        default:
          return;
      }
    }

    return;
  }, [location]);

  const sheets = useMemo(() => {
    let list = [];

    if (!onlySection || (onlySection && definedSection === "citizens-ideas")) {
      const citizensItem = citizens.length > 0 ? citizens[0] : {};
      const citizenNodes = Object.keys(citizensItem);
      const citizenNode =
        citizenNodes.length > 0 ? citizensItem[citizenNodes[0]] : {};
      const { nodes: ci_nodes = {} } = citizenNode ? citizenNode : {};

      const citizensColumns = Object.keys(ci_nodes).map((node_name) => {
        const { name } = ci_nodes[node_name] ? ci_nodes[node_name] : {};

        return {
          title: name,
          dataIndex: node_name,
          render: (item = {}) => {
            const { type, text, attachment = {} } = item;
            const { variant } = attachment;

            if (type === "plain" || type === "username" || type === "email")
              return text;

            if (type === "variant") return variant;
          },
        };
      });

      list.push(
        <ExcelSheet data={citizens} name="Идеи горожан">
          {citizensColumns.map((item = {}) => {
            const { title, dataIndex, render = () => {} } = item;

            let value = (cols = {}) => {
              const v = cols[dataIndex];

              const { type, text, attachment = {}, nodes = {} } = v ? v : {};
              const { variant } = attachment;

              let b = null;

              if (type === "plain" || type === "username" || type === "email")
                b = text;

              if (type && type === "variant") {
                let { answers = [] } = nodes[dataIndex] ? nodes[dataIndex] : {};

                if (variant && variant.startsWith("answer.0.0.")) {
                  answers = [...updatedCities];
                }

                const answerIndexSplit = variant ? variant.split(".") : [];
                const answerIndex =
                  answerIndexSplit.length > 0
                    ? answerIndexSplit[answerIndexSplit.length - 1]
                    : null;
                const answerData = answerIndex ? answers[answerIndex] : {};
                const { name: answer } = answerData ? answerData : {};
                b = answer;
              }

              return b ? b : "(Отсутствует)";
            };

            return <ExcelColumn label={title} value={value} />;
          })}
        </ExcelSheet>
      );
    }

    if (!onlySection || (onlySection && definedSection === "members")) {
      if (dataMembers.length > 0) {
        list.push(
          <ExcelSheet data={dataMembers} name="Участники">
            {columnsMembers().map((item = {}) => {
              const { title, dataIndex } = item;

              let value = dataIndex === "occupation" ? handleGroup : dataIndex;

              return <ExcelColumn label={title} value={value} />;
            })}
          </ExcelSheet>
        );
      }
    }

    if (
      (!onlySection || (onlySection && definedSection === "executors")) &&
      dataExecutors.length > 0
    )
      list.push(
        <ExcelSheet data={dataExecutors} name="Исполнители">
          {columnsExecutors.map((item = {}) => {
            const { title, dataIndex } = item;

            let value = (cols = {}) => {
              const v = cols[dataIndex];
              return v ? v : "(Отсутствует)";
            };

            return <ExcelColumn label={title} value={value} />;
          })}
        </ExcelSheet>
      );

    if (
      (!onlySection || (onlySection && definedSection === "projects-kb")) &&
      dataProjectsKB.length > 0
    )
      list.push(
        <ExcelSheet data={dataProjectsKB} name="Проекты КБ">
          {columnsProjectsKB.map((item = {}) => {
            const { title, dataIndex } = item;

            const handleStep = (cols = {}) => {
              const step = cols[dataIndex];

              const items = step
                ? step.reduce(
                    (prev, { name }, i) =>
                      i === 0 ? `${name}` : `${prev}, ${name}`,
                    ""
                  )
                : "";

              return items;
            };

            let value = dataIndex.startsWith("step") ? handleStep : dataIndex;

            return <ExcelColumn label={title} value={value} />;
          })}
        </ExcelSheet>
      );

    if (
      (!onlySection || (onlySection && definedSection === "projects-cities")) &&
      projectsCities.length > 0
    )
      list.push(
        <ExcelSheet data={projectsCities} name="Проекты городов">
          {columnsProjectsCities.map((item = {}) => {
            const { title, dataIndex } = item;

            const handleApproveStatus = (cols = {}) => {
              const { is_approved } = cols;

              return is_approved ? "Да" : "Нет";
            };

            const handleFiles = (cols = {}) => {
              const { file1 = [] } = cols;

              const items = file1
                ? file1.reduce(
                    (prev, name, i) =>
                      i === 0 ? `${name}` : `${prev}, ${name}`,
                    ""
                  )
                : null;

              return items ? items : "(Отсутствуют)";
            };

            let value =
              dataIndex === "is_approved"
                ? handleApproveStatus
                : dataIndex === "file1"
                ? handleFiles
                : dataIndex;

            return <ExcelColumn label={title} value={value} />;
          })}
        </ExcelSheet>
      );

    if (
      (!onlySection || (onlySection && definedSection === "plans")) &&
      userDocs.length > 0
    )
      list.push(
        <ExcelSheet data={userDocs} name="Планы">
          {columnsPlans.map((item = {}) => {
            const { title, dataIndex } = item;

            let value = dataIndex === "cr" ? handleDate : dataIndex;

            return <ExcelColumn label={title} value={value} />;
          })}
        </ExcelSheet>
      );

    if (
      (!onlySection || (onlySection && definedSection === "interests")) &&
      userInterests.length > 0
    )
      list.push(
        <ExcelSheet data={userInterests} name="Учет интересов граждан">
          {columnsPlans.map((item = {}) => {
            const { title, dataIndex } = item;

            let value = dataIndex === "cr" ? handleDate : dataIndex;

            return <ExcelColumn label={title} value={value} />;
          })}
        </ExcelSheet>
      );

    if (
      (!onlySection || (onlySection && definedSection === "reports")) &&
      reports.length > 0
    )
      list.push(
        <ExcelSheet data={reports} name="Отчеты">
          {columnsReports.map((item = {}) => {
            const { title, dataIndex } = item;

            const handleLocalDate = (cols = {}, dataIndex) => {
              let cr = cols && cols[dataIndex] ? cols[dataIndex] : null;

              if (cr) return moment(cr).format("dddd, MMMM Do YYYY, HH:mm:ss");
              return "(Отсутствует)";
            };

            let value =
              dataIndex === "cr1" || dataIndex === "cr2" || dataIndex === "cr3"
                ? (a) => handleLocalDate(a, dataIndex)
                : dataIndex;

            return <ExcelColumn label={title} value={value} />;
          })}
        </ExcelSheet>
      );

    return list;
  }, [
    dataMembers,
    dataExecutors,
    dataProjectsKB,
    projectsCities,
    userDocs,
    reports,
    citizens,
    onlySection,
    definedSection,
  ]);

  return (
    <>
      {/* Excel */}
      <ExcelFile
        element={
          <Btn type={"primary"} data-type="small">
            {label}
          </Btn>
        }
      >
        {sheets}
      </ExcelFile>

      {/* */}
    </>
  );
};

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 14,
    }}
    spin
  />
);

const ExcelTable = () => {
  /* Members */
  const [getMembers, { data: dataMembers_gql, loading: loadingMembers }] =
    makeQuery(GET_MEMBERS);

  /* Executors */
  const [getExecutors, { data: dataExecutors_gql, loading: loadingExecutors }] =
    makeQuery(GET_EXECUTORS);

  /* ProjectsKB */
  const [
    getProjectKB,
    { data: dataProjectsKB_gql, loading: loadingProjectsKB },
  ] = makeQuery(GET_PROJECTSKB);
  const [
    getAllProjects,
    { data: dataAllProjects_gql, loading: loadingAllProjects },
  ] = makeQuery(GET_ALL_PROJECTS);

  /* Projects cities */
  const [
    getProjectCities,
    { data: dataProjectsCities_gql, loading: loadingProjectsCities },
  ] = makeQuery(GET_PROJECTS_CITIES);

  /* Docs */
  const [getUserdocs, { data: dataDocs_gql, loading: loadingDocs }] =
    makeQuery(GET_USERDOCS);

  /* Interests consideration */
  const [
    getInterestsDocs,
    { data: dataInterests_gql, loading: loadingInterests },
  ] = makeQuery(GET_INTERESTS);

  /* Docs Points */
  const [getReports, { data: dataReports_gql, loading: loadingReports }] =
    makeQuery(GET_USERDOCSPOINTS);

  const [
    getCitizenIdeas,
    { data: dataCitizens_gql, loading: loadingCitizens },
  ] = makeQuery(GET_CITIZENS_IDEAS);

  useEffect(() => {
    getMembers({ variables: {} });
    getExecutors({ variables: {} });
    getProjectKB({ variables: {} });
    getAllProjects();
    getProjectCities({ variables: {} });
    getUserdocs({ variables: {} });
    getInterestsDocs({ variables: {} });
    getReports({ variables: {} });
    getCitizenIdeas({ variables: {} });
  }, []);

  const loading = useMemo(() => {
    if (
      loadingMembers ||
      loadingExecutors ||
      loadingProjectsKB ||
      loadingAllProjects ||
      loadingProjectsCities ||
      loadingDocs ||
      loadingReports ||
      loadingCitizens ||
      loadingInterests
    )
      return true;

    return false;
  }, [
    loadingMembers,
    loadingExecutors,
    loadingProjectsKB,
    loadingAllProjects,
    loadingProjectsCities,
    loadingDocs,
    loadingReports,
    loadingCitizens,
    loadingInterests,
  ]);

  const dataMembers = useMemo(() => {
    return handleMembersData(dataMembers_gql);
  }, [dataMembers_gql]);

  const dataExecutors = useMemo(() => {
    return handleExecutorsData(dataExecutors_gql);
  }, [dataExecutors_gql]);

  const dataProjectsKB = useMemo(() => {
    return handleProjectsKBData(dataProjectsKB_gql, dataAllProjects_gql);
  }, [dataProjectsKB_gql, dataAllProjects_gql]);

  const projectsCities = useMemo(() => {
    return handleDataA(dataProjectsCities_gql);
  }, [dataProjectsCities_gql]);

  const userDocs = useMemo(() => {
    return handleDocsData(dataDocs_gql);
  }, [dataDocs_gql]);

  const userInterests = useMemo(() => {
    return handleDocsData(dataInterests_gql);
  }, [dataInterests_gql]);

  const reports = useMemo(() => {
    return handleReportsData(dataReports_gql);
  }, [dataReports_gql]);

  const citizens = useMemo(() => {
    return handleCitizensData(dataCitizens_gql);
  }, [dataCitizens_gql]);

  return (
    <>
      <Wrapper>
        {loading ? (
          <Spin style={{ margin: "0 14px" }} indicator={antIcon} />
        ) : (
          <>
            <span style={{ fontWeight: "bold", paddingLeft: "5px" }}>
              Скачать:{" "}
            </span>

            <ExcelButton
              label={"Этот раздел"}
              onlySection
              {...{
                dataMembers,
                dataExecutors,
                dataProjectsKB,
                projectsCities,
                userDocs,
                userInterests,
                reports,
                citizens,
              }}
            />

            <ExcelButton
              label={"Все разделы"}
              {...{
                dataMembers,
                dataExecutors,
                dataProjectsKB,
                projectsCities,
                userDocs,
                userInterests,
                reports,
                citizens,
              }}
            />
          </>
        )}
      </Wrapper>
    </>
  );
};

export default ExcelTable;
