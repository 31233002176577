import {
  Form,
  Modal,
  Skeleton,
  Tabs,
  Input,
  Select,
  notification,
  Space,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";

import {
  GET_MEMBER,
  GET_MEMBERS,
  UPDATE_MEMBER,
} from "../../../../../queries/5-steps";
import { Btn } from "../components/excel";
import makeQuery from "../components/query";

import { base } from "../../../../../../src/clients/gql-client";
import { queryHeader } from "../components/query";
import { useMutation } from "@apollo/client";
import { emailValidationRules, requiredRule } from "./utils/email-validator";

const AddMember = ({
  member,
  setMember,
  memberLogId,
  refetchMembers,
  refetchNewMembers,
}) => {
  const [getMember, { data: memberData, loading, error }] =
    makeQuery(GET_MEMBER);

  const [updateMember, { error: upd_error }] = useMutation(UPDATE_MEMBER, {
    ...queryHeader,
    refetchQueries: [{ query: GET_MEMBERS }, "getMembers"],
    onCompleted: (e) => {
      notification.success({
        message: `Готово!`,
        placement: "bottom",
      });

      setMember();
    },
  });

  if (upd_error) console.log("upd_error", upd_error);

  useEffect(() => {
    if (member) {
      getMember({
        variables: { performer_id: member },
        fetchPolicy: "no-cache",
      });
    }
  }, [member, memberLogId]);

  const initProps = useMemo(() => {
    if (memberData) {
      const { app5s_userPerformer: a } = memberData;

      let result;

      if (a) {
        const { name, position, user = {}, account = {} } = a;
        const { password } = account;
        const { meta = [] } = user;
        const [m = {}] = meta;
        const { first_name, last_name, email, occupation, user_id } = m;

        result = {
          name,
          email,
          position,
          first_name,
          last_name,
          occupation,
          password,
          user_id,
        };
      }

      return result;
    }
  }, [memberData, member]);

  const successFinish = () => {
    notification.success({ message: "Успешно!" });
    if (typeof refetchMembers === "function") {
      refetchMembers();
    }

    if (typeof refetchNewMembers === "function") {
      refetchNewMembers();
    }

    setMember();
  };

  const errorFinish = () => {
    notification.error({ message: "Ошибка на сервере!" });
    setMember();
  };

  const handleFinish = (e) => {
    const { email, first_name, last_name, name, occupation } = e;

    const values = {
      name,
      city: first_name,
      region: last_name,
      group: occupation,
      email,
    };

    if (memberData) {
      if (!initProps) {
        fetch(`${base}/v1/auth/app5s/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
          .then((response) => response.json())
          .then(successFinish)
          .catch(errorFinish);
      } else {
        const { user_id } = initProps;

        if (user_id) {
          const values = {
            id: user_id,
            email,
            name,
            group: occupation,
            city: last_name,
            region: first_name,
          };

          fetch(`${base}/v1/auth/app5s/updateuser`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          })
            .then((response) => response.json())
            .then(successFinish)
            .catch(errorFinish);
        }
      }
    }
  };

  const handlePassword = () => {
    const { user_id } = initProps;
    const values = { id: user_id };

    fetch(`${base}/v1/auth/app5s/updatecode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then(successFinish)
      .catch(errorFinish);
  };

  const [form] = Form.useForm();
  const [capsLock, setCapsLock] = useState(false);

  const onEmailChange = (e) => {
    const inputValue = e.target.value;
    const isUppercase = inputValue !== inputValue.toLowerCase();

    if (isUppercase && !capsLock) {
      setCapsLock(true);
    } else if (!isUppercase && capsLock) {
      setCapsLock(false);
    }

    form.setFieldsValue({
      email: inputValue.toLowerCase(),
    });
  };

  if (error) console.log("error", error);

  return (
    <Modal
      open={member ? true : false}
      onCancel={() => setMember()}
      onOk={() => setMember()}
      footer={false}
      key={`dd:${member}`}
      width={600}
    >
      {loading && <Skeleton active />}
      {member &&
        ((!loading && memberData && initProps) ||
          (!loading && memberData && !memberData.app5s_userPerformer)) && (
          <Tabs>
            <Tabs.TabPane tab="Шаг 1. Общая информация" key="step1">
              <Form
                form={form}
                onFinish={handleFinish}
                initialValues={initProps ? initProps : { occupation: "A" }}
                key={`f:${member}`}
                layout="vertical"
                style={{ maxWidth: "450px" }}
              >
                <Form.Item name="name" label="ФИО">
                  <Input />
                </Form.Item>

                <Form.Item
                  rules={requiredRule}
                  name="first_name"
                  label="Название города"
                >
                  <Input />
                </Form.Item>

                <Form.Item rules={requiredRule} name="last_name" label="Регион">
                  <Input />
                </Form.Item>

                <Form.Item
                  rules={requiredRule}
                  name="occupation"
                  label="Группа"
                >
                  <Select
                    options={[
                      { value: "A", label: "A" },
                      { value: "B", label: "B" },
                      { value: "C", label: "C" },
                      { value: "D", label: "D" },
                      { value: "E", label: "E" },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  rules={emailValidationRules}
                  name="email"
                  label="Email"
                >
                  <Input onChange={onEmailChange} />
                </Form.Item>

                <Form.Item>
                  <Btn type="primary" htmlType="submit">
                    Добавить
                  </Btn>
                </Form.Item>
              </Form>
            </Tabs.TabPane>

            <Tabs.TabPane
              disabled={!initProps}
              tab="Шаг 2. Код доступа"
              key="step2"
            >
              <div
                style={{
                  padding: "15px",
                  borderRadius: "15px",
                  background: "rgba(0,0,0,.05)",
                }}
              >
                <Space direction="vertical">
                  <div>{initProps && initProps.password}</div>
                  <div>
                    <Btn onClick={handlePassword} style={{ marginLeft: "0" }}>
                      Сгенерировать новый
                    </Btn>
                  </div>
                </Space>
              </div>
            </Tabs.TabPane>
          </Tabs>
        )}
    </Modal>
  );
};

export default AddMember;
