import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { CustomTable } from "../index";
import {
  GET_PARTNER,
  GET_PARTNERS,
  REMOVE_PARTNER,
} from "../../../../../queries/5-steps";
import makeQuery from "../components/query";
import columns from "./columns";
import columnsWire from "../wire/columns";
import { v4 as uuidv4 } from "uuid";

import {
  Input,
  Row,
  Col,
  Typography,
  Modal,
  Button,
  Form,
  notification,
} from "antd";

import PageWrapper from "../index";
import handleData from "./handleData";
import useStatusStore from "../../../../../store/status-store";
import { Btn } from "../components/excel";
import AddPartner from "./add-partner";
import { useMutation } from "@apollo/client";
import { queryHeader } from "../components/query";

const { Text } = Typography;

export const TotalCounter = styled(Text)`
  font-weight: 500;
  margin-bottom: 24px;
  padding-bottom: 24px;
`;

const PartnersPage = () => {
  const [getPartners, { data: partnersData, loading }] =
    makeQuery(GET_PARTNERS);
  const [getPartner, { data: partnerData }] = makeQuery(GET_PARTNER);

  const [deletePartner] = useMutation(REMOVE_PARTNER, {
    ...queryHeader,
    refetchQueries: [{ query: GET_PARTNERS }, "getPartners"],
    onCompleted: () => {
      notification.success({
        message: `Готово!`,
        placement: "bottom",
      });
    },
  });

  const search = useStatusStore(({ search }) => search);

  useEffect(() => {
    getPartners({ variables: { search } });
  }, [search]);

  const partners = useMemo(() => {
    const result = handleData(partnersData);
    return result;
  }, [partnersData]);

  const [modal, setModal] = useState();
  const [partner, setPartner] = useState();
  const [partnerLogId, setParnetLogId] = useState(uuidv4());

  useEffect(() => {
    if (modal) {
      const { id } = modal;

      if (id) {
        getPartner({
          variables: { partner_id: id },
        });
      }
    }
  }, [modal]);

  const partnerCities = useMemo(() => {
    if (partnerData) {
      const { app_5steps_partners = [] } = partnerData;
      const [partner = {}] = app_5steps_partners;
      const { users = [] } = partner;

      return users.map((item = {}) => {
        const { user = {} } = item;
        const { first_name = "", last_name = "" } = user;

        return { first_name, last_name };
      });
    }
  }, [partnerData]);

  const handleDelete = (partner_id) => {
    deletePartner({
      variables: {
        partner_id,
      },
    });
  };

  return (
    <PageWrapper title="Партнеры">
      <Modal
        open={modal ? true : false}
        onCancel={() => setModal()}
        onOk={() => setModal()}
        footer={false}
      >
        <div style={{ display: "flex", flexDirection: "column" }}></div>
        {partnerCities &&
          partnerCities.map((item = {}) => {
            const { first_name, last_name } = item;
            return (
              <div
                style={{
                  padding: "8px 5px",
                  borderBottom: "1px solid rgba(0,0,0,.08)",
                }}
              >{`${first_name} ${last_name}`}</div>
            );
          })}
      </Modal>

      {partner && (
        <AddPartner
          {...{ partner, setPartner, partnerLogId }}
          key={`sdf:${partner}:${partnerLogId}`}
        />
      )}

      {
        <>
          {partners && (
            <TotalCounter>Всего партнеров: {partners.length} </TotalCounter>
          )}

          <Btn type="primary" onClick={() => setPartner(uuidv4())}>
            Добавить партнера
          </Btn>

          <br />
          <br />

          {loading || !partners ? (
            <CustomTable dataSource={Array(12).fill(1)} columns={columnsWire} />
          ) : (
            <CustomTable
              dataSource={partners}
              columns={columns(
                setModal,
                setPartner,
                handleDelete,
                setParnetLogId
              )}
            />
          )}
        </>
      }
    </PageWrapper>
  );
};

export default PartnersPage;
