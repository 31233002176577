import React from "react";
import { Typography } from "antd";
import { GhostText } from "../members/columns";

const { Text } = Typography;

const nullStatus = <GhostText>(отсутствует)</GhostText>;

const columns = [
  {
    title: "Город",
    dataIndex: "city_name",
    render: (e) => (e ? e : nullStatus),
    fixed: "left",
  },
  {
    title: "Глава города",
    dataIndex: "name1",
    render: (e) => (e ? e : nullStatus),
  },
  {
    title: "Email главы",
    dataIndex: "email1",
    render: (e) => (e ? <Text copyable>{e}</Text> : nullStatus),
  },
  {
    title: "Замглавы города",
    dataIndex: "name2",
    render: (e) => (e ? e : nullStatus),
  },
  {
    title: "Email замглавы",
    dataIndex: "email2",
    render: (e) => (e ? <Text copyable>{e}</Text> : nullStatus),
  },
  {
    title: "Исполнитель",
    dataIndex: "name3",
    render: (e) => (e ? e : nullStatus),
  },
  {
    title: "Должность",
    dataIndex: "position3",
    render: (e) => (e ? e : nullStatus),
  },
  {
    title: "Email исполнителя",
    dataIndex: "email3",
    render: (e) => (e ? <Text copyable>{e}</Text> : nullStatus),
  },
];

export default columns;
