import React, { useEffect, useState, useMemo } from "react";

import { CustomTable } from "../index";
import makeQuery from "../components/query";
import { GET_USERDOCS } from "../../../../../queries/5-steps";

import columns from "./columns-vis";
import PageWrapper from "../index";
import SearchBar from "../components/search";

import { TotalCounter } from "../members";
import ExcelTable from "../components/excel";

import handleData from "./handleData";
import useStatusStore from "../../../../../store/status-store";

const PlansPage = () => {
  const search = useStatusStore(({ search }) => search);

  const [getUserdocs, { data }] = makeQuery(GET_USERDOCS);

  useEffect(() => {
    getUserdocs({ variables: { search } });
  }, [search]);

  const docs = useMemo(() => {
    return handleData(data);
  }, [data]);

  return (
    <PageWrapper title="Планы">
      {/* <SearchBar {...{ setSearch }} /> */}

      {docs && (
        <TotalCounter>Всего записей по городам: {docs.length} </TotalCounter>
      )}
      {docs && <ExcelTable />}
      <br />
      <br />

      <CustomTable dataSource={docs} columns={columns} />
    </PageWrapper>
  );
};

export default PlansPage;
