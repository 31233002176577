import React from "react";

import { Skeleton } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 30px;
  min-width: 100%;

  && .ant-skeleton-element {
    min-width: 100%;
  }

  && .ant-skeleton-input {
    height: 25px;
  }
`;

const columns = [
  {
    title: (
      <Wrapper>
        <Skeleton.Input active />
      </Wrapper>
    ),
    dataIndex: "",
    render: (e) => (
      <Wrapper>
        <Skeleton.Input active />
      </Wrapper>
    ),
    width: "20%",
  },
  {
    title: (
      <Wrapper>
        <Skeleton.Input active />
      </Wrapper>
    ),
    dataIndex: "",
    render: (e) => (
      <Wrapper>
        <Skeleton.Input active />
      </Wrapper>
    ),
    width: "20%",
  },
  {
    title: (
      <Wrapper>
        <Skeleton.Input active />
      </Wrapper>
    ),
    dataIndex: "",
    render: (e) => (
      <Wrapper>
        <Skeleton.Input active />
      </Wrapper>
    ),
    width: "20%",
  },
  {
    title: (
      <Wrapper>
        <Skeleton.Input active />
      </Wrapper>
    ),
    dataIndex: "",
    render: (e) => (
      <Wrapper>
        <Skeleton.Input active />
      </Wrapper>
    ),
    width: "40%",
  },
];

export default columns;
