import React from "react";
import moment from "moment";
import { DwnlBtn, None } from "../reports/__styles";
import { timeFormat, open } from "../reports/columns-vis";

const columns = [
  {
    title: "Город",
    dataIndex: "city_name",
    render: (e) => e,
    width: "30%",
  },
  {
    title: "Файл",
    dataIndex: "file",
    width: "70%",
    render: (e, { cr }) =>
      e ? (
        <>
          <DwnlBtn onClick={() => open(e)}>
            <a>Скачать файл</a>
            <span>{moment(cr).format(timeFormat)}</span>
          </DwnlBtn>
        </>
      ) : (
        <None>(Отсутствует)</None>
      ),
  },
];

export default columns;
