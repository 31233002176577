import React from "react";
import { Switch as AntSwitch } from "antd";

const Switch = (props = {}) => {
  const { value, onChange = () => {} } = props;

  return (
    <div
      style={{
        display: "flex",
        borderRadius: "8px",
        border: "1px solid lightgrey",
        padding: "10px",
      }}
    >
      <div style={{ marginRight: "24px" }}>Для всех городов:</div>

      <AntSwitch checked={value} onChange={onChange} />
    </div>
  );
};

export default Switch;
