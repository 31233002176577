import React, { useEffect, useMemo, useState } from "react";
import columns from "./columns";

import { GET_EXECUTORS } from "../../../../../queries/5-steps";
import { CustomTable } from "../index";
import makeQuery from "../components/query";
import columnsWire from "../wire/columns";

import PageWrapper from "../index";
import SearchBar from "../components/search";
import { TotalCounter } from "../members";
import ExcelTable from "../components/excel";
import handleData from "./handleData";
import useStatusStore from "../../../../../store/status-store";

const ExecutorsPage = () => {
  const search = useStatusStore(({ search }) => search);

  const [getExecutors, { data: executorsData, loading }] =
    makeQuery(GET_EXECUTORS);

  useEffect(() => {
    getExecutors({ variables: { search } });
  }, [search]);

  const executors = useMemo(() => {
    return handleData(executorsData);
  }, [executorsData]);

  return (
    <PageWrapper title="Исполнители">
      {/* <SearchBar {...{ setSearch }} /> */}

      {executors && (
        <TotalCounter>Всего анкет по городам: {executors.length} </TotalCounter>
      )}
      {executors && <ExcelTable />}
      <br />
      <br />

      {loading ? (
        <CustomTable dataSource={Array(12).fill(1)} columns={columnsWire} />
      ) : (
        <CustomTable
          scroll={{ x: 2500 }}
          dataSource={executors}
          columns={columns}
        />
      )}
    </PageWrapper>
  );
};

export default ExecutorsPage;
