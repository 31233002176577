import { ContextHeader } from "../../../../../env";
import { useMutation } from "@apollo/client";
import { notification } from "antd";

const handleComplete = () => {
  notification.success({ message: "Успешно" });
};

const handleError = () => {
  notification.error({ message: "Произошла ошибка на сервере" });
};

export const queryHeader = {
  context: {
    headers: {
      "x-hasura-admin-secret": ContextHeader,
    },
  },
};

const makeMutation = (queryType) => {
  return useMutation(queryType, {
    ...queryHeader,
    onCompleted: handleComplete,
    onError: handleError,
  });
};

export default makeMutation;
