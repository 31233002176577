import citiesData from "../members/extra-meta1.json";

const handleData = (executorsData) => {
  if (executorsData) {
    const { app5s_userPerformers = [] } = executorsData;

    let _executors = {};

    app5s_userPerformers.map((userData) => {
      const { user_id, user = {}, cr } = userData;

      if (!_executors[user_id]) {
        const { meta = [] } = user;
        const {
          email: city_email,
          first_name,
          last_name,
        } = meta.length > 0 ? meta[0] : [];

        let city_name = "";
        city_name += first_name ? first_name : "";
        city_name += last_name ? ` ${last_name}` : "";

        if (city_email) {
          const findCity = citiesData.find(({ email: em }) => {
            return em.toLowerCase().includes(city_email.toLowerCase());
          });

          if (findCity) {
            const { name, region } = findCity;

            city_name = `${name} ${region}`;
          }
        }

        _executors[user_id] = { city_name, city_email, city_id: user_id, cr };
      }

      const { ordering, name, email, position } = userData;
      _executors[user_id][`name${ordering}`] = name;
      _executors[user_id][`email${ordering}`] = email;
      if (ordering === 3) _executors[user_id][`position${ordering}`] = position;

      return userData;
    });

    const result = Object.keys(_executors).map((key) => _executors[key]);

    return result.sort((i1 = {}, i2 = {}) => {
      const { cr: cr1 } = i1;
      const { cr: cr2 } = i2;

      if (cr2 < cr1) {
        return -1;
      }
      if (cr2 > cr1) {
        return 1;
      }
      return 0;
    });
  }

  return;
};

export default handleData;
