import React from "react";
import { Switch, Segmented, notification } from "antd";
import styled from "styled-components";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

const openWaitingNote = () => {
  notification.open({
    message: "Статус в процессе изменения...",
  });
};

const MultiSwitch = styled(Segmented)`
  && label {
    margin-bottom: 0;
  }

  && .ant-segmented-item-label {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
    }
  }
`;

const columns = [
  {
    title: "Город",
    dataIndex: "city_name",
    render: (e) => e,
    width: "20%",
  },
  {
    title: "Название проекта",
    dataIndex: "title",
    render: (e) => e,
    width: "70%",
  },

  {
    title: "Одобрен",
    dataIndex: "is_approved",
    render: (e, { handleApproving, id, is_rejected }) => {
      const is_approved = e;

      let val = "waiting";
      if (is_approved && !is_rejected) {
        val = "approved";
      } else if (!is_approved && is_rejected) {
        val = "rejected";
      }

      return (
        <MultiSwitch
          key={`switch:${id}`}
          value={val}
          onChange={(e) => {
            openWaitingNote();
            return handleApproving(id, e);
          }}
          options={[
            {
              label: (
                <div>
                  <CloseCircleFilled />
                </div>
              ),
              value: "rejected",
            },
            {
              label: <div></div>,
              value: "waiting",
            },
            {
              label: (
                <div>
                  <CheckCircleFilled />
                </div>
              ),
              value: "approved",
            },
          ]}
        />
      );
      /*<Switch
        key={`switch${id}`}
        defaultChecked={e}
        onChange={(status) => {
          return handleApproving(id, status);
        }}
      />*/
    },
    width: "10%",
  },
];

export default columns;
/* onClick={() => handleApproving(id, e)} */
