const handleData = (partnersData) => {
  if (partnersData) {
    const { app_5steps_partners = [] } = partnersData;

    return app_5steps_partners.map((partnerData) => {
      const {
        title,
        region_text,
        mail,
        is_global,
        interest,
        fio,
        id,
        dateput,
        contacts,
        comment,
        city_text,
        users_aggregate = {},
      } = partnerData;

      const { aggregate = {} } = users_aggregate;
      const { count = 0 } = aggregate;

      return {
        title,
        region_text,
        mail,
        is_global,
        interest,
        fio,
        id,
        dateput,
        contacts,
        comment,
        city_text,
        cities_count: count,
      };
    });
  }

  return;
};

export default handleData;
