import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RestLink } from "apollo-link-rest";
import { appConfig } from "../env";

const daDataKey = process.env.REACT_APP_DADATA_API_KEY;
const suggest = process.env.REACT_APP_DADATA_ENDPOINT;
export const base = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : appConfig.api.base;
const auth = base + "/v1/auth/crowdsourcing";
const data = base + "/v1/graphql";

const link = createHttpLink({
  uri: data,
});
const tokenLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("cwd-admin-token");

  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  }

  return headers;
});
const authLink = new RestLink({
  uri: auth + "/signin",
  customFetch: (uri, options) =>
    fetch(uri, options).then((res) => {
      const { body, status, statusText, headers } = res;

      if (status === 404) {
        const response = new Response(body, {
          status: 401,
          statusText,
          headers,
        });

        return Promise.resolve(response);
      } else {
        return Promise.resolve(res);
      }
    }),
  endpoints: {
    signOut: {
      uri: auth + "/signout",
    },
    signUp: {
      uri: auth + "/signup",
    },
    check: {
      uri: auth + "/check",
    },
    recovery: {
      uri: auth + "/recovery",
    },
  },
  bodySerializers: {
    empty: (_, headers) => ({
      headers,
    }),
  },
});
const daDataLink = new RestLink({
  uri: suggest,
  endpoints: {
    address: {
      uri: suggest + "/address",
      responseTransformer: async (response) =>
        response
          .json()
          .then(({ suggestions }) => suggestions)
          .catch((e) => Promise.reject(e)),
    },
  },
  headers: {
    Authorization: `Token ${daDataKey}`,
  },
});

const client = new ApolloClient({
  link: from([tokenLink, link]),
  cache: new InMemoryCache(),
});
const authClient = new ApolloClient({
  link: authLink,
  cache: new InMemoryCache(),
});
const daDataClient = new ApolloClient({
  link: daDataLink,
  cache: new InMemoryCache(),
});

client
  .query({
    query: gql`
      query MyQuery {
        metric_met_sen_rep {
          bot_id
          cnt
          dialog_id
          is_published
          negative
          platform_id
          positive
          project_id
          sentiment
          token
        }
      }
    `,
  })
  .then(() => true);

export default client;
export { authClient, daDataClient };
