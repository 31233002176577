import { handlePassword } from "../../members/columns";
import moment from "moment";

const handleGroup = (col = {}) => {
  let { occupation: e } = col;

  e =
    (e === "A" ||
      e === "B" ||
      e === "C" ||
      e === "D" ||
      e === "E" ||
      e === "F") &&
    e;

  return e ? e : "(Отсутствует)";
};

const handlePass = (col = {}) => {
  const { email } = col;

  const password = handlePassword(email);

  return password ? password : "(Отсутствует)";
};

const handleDate = (cols = {}) => {
  const { cr } = cols;

  if (cr) return moment(cr).format("dddd, MMMM Do YYYY, HH:mm:ss");
  return "(Отсутствует)";
};

export { handleGroup, handlePass, handleDate };
