import React, { useState } from "react";
import styled from "styled-components";

import { GET_MEMBERS, GET_NEW_MEMBERS } from "../../../../../queries/5-steps";
import makeQuery from "../components/query";

import { Typography, Tabs } from "antd";

import PageWrapper from "../index";
import { Btn } from "../components/excel";
import useStatusStore from "../../../../../store/status-store";

import { v4 as uuidv4 } from "uuid";
import AddMember from "./add-member";
import ActiveMembers from "./active-members";
import NewUsers from "./new-users";

const { Text } = Typography;

export const TotalCounter = styled(Text)`
  font-weight: 500;
  margin-bottom: 24px;
  padding-bottom: 24px;
`;

const MembersPage = () => {
  const [getMembers, { refetch: refetchMembers, data: membersData, loading }] =
    makeQuery(GET_MEMBERS);

  const [
    getNewMembers,
    {
      refetch: refetchNewMembers,
      data: newMembersData,
      loading: new_loading,
      error,
    },
  ] = makeQuery(GET_NEW_MEMBERS);

  console.log("error", error);

  const search = useStatusStore(({ search }) => search);

  const [modal, setModal] = useState();
  const [member, setMember] = useState();
  const [memberLogId, setMemberLogId] = useState(uuidv4());

  const [tab, setTab] = useState("active-users");

  const addMemBtn = (
    <Btn type="primary" onClick={() => setMember(uuidv4())}>
      Добавить участника
    </Btn>
  );

  return (
    <PageWrapper title="Участники">
      {member && (
        <AddMember
          {...{
            member,
            setMember,
            memberLogId,
            refetchMembers,
            refetchNewMembers,
          }}
          key={`sdf:${member}:${memberLogId}`}
        />
      )}

      <Tabs onChange={setTab} activeKey={tab}>
        <Tabs.TabPane tab="Активные участники" key="active-users">
          <ActiveMembers
            {...{
              getMembers,
              loading,
              setModal,
              membersData,
              search,
              setMember,
              memberLogId,
              setMemberLogId,
              addMemBtn,
            }}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Новые аккаунты" key="new-users">
          <NewUsers
            {...{
              getMembers: getNewMembers,
              loading: new_loading,
              setModal,
              membersData: newMembersData,
              search,
              setMember,
              memberLogId,
              setMemberLogId,
              addMemBtn,
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </PageWrapper>
  );
};

export default MembersPage;
