import React, { useEffect, useMemo, useState } from "react";
import {
  Input,
  Row,
  Col,
  Typography,
  Modal,
  Button,
  Form,
  Select,
  Tabs,
  Skeleton,
  notification,
} from "antd";
import { Btn } from "../components/excel";
import Transfer from "./transfer";
import Switch from "./switch";

import {
  ADD_PARTNER,
  GET_PARTNERS,
  GET_PARTNER_FORM,
  UPDATE_CITIES,
  UPDATE_PARTNER,
} from "../../../../../queries/5-steps";
import makeQuery from "../components/query";
import { useMutation } from "@apollo/client";
import { queryHeader } from "../components/query";

const AddPartner = ({ partner, setPartner, partnerLogId }) => {
  const isEdit = useMemo(() => {
    if (typeof partner === "number") {
      return true;
    }
  }, [partner]);

  const [addPartner] = useMutation(ADD_PARTNER, {
    ...queryHeader,
    refetchQueries: [{ query: GET_PARTNERS }, "getPartners"],
    onCompleted: (e) => {
      const { insert_app_5steps_partners_one: a = {} } = e;
      setPartner(a.id);

      notification.success({
        message: `Готово!`,
        placement: "bottom",
      });
    },
  });

  const [updatePartner] = useMutation(UPDATE_PARTNER, {
    ...queryHeader,
    refetchQueries: [{ query: GET_PARTNERS }, "getPartners"],
    onCompleted: (e) => {
      notification.success({
        message: `Готово!`,
        placement: "bottom",
      });
    },
  });

  const [updateCities] = useMutation(UPDATE_CITIES, {
    ...queryHeader,
    refetchQueries: [{ query: GET_PARTNERS }, "getPartners"],
    onCompleted: (e) => {
      notification.success({
        message: `Готово!`,
        placement: "bottom",
      });
    },
  });

  const handleFinishMain = (e) => {
    const { title, fio, mail, interest, comment } = e;

    if (!isEdit) {
      addPartner({
        variables: { partner: { title, fio, mail, interest, comment } },
      });
    } else {
      updatePartner({
        variables: {
          partner_id: partner,
          _set: { title, fio, mail, interest, comment },
        },
      });
    }
  };

  const [getPartner, { data: partnerData, loading, error }] =
    makeQuery(GET_PARTNER_FORM);

  if (error) console.log("error", error);

  useEffect(() => {
    if (typeof partner === "number") {
      getPartner({
        variables: { partner_id: partner },
        fetchPolicy: "no-cache",
      });
    }
  }, [partner, partnerLogId]);

  const initProps = useMemo(() => {
    if (partnerData) {
      const {
        app_5steps_partners: a = [],
        app_5steps_partners_to_users: b = [],
      } = partnerData;

      let result = {};

      if (a && a.length > 0) {
        const [main = {}] = a;
        result = { ...result, ...main };
      }

      if (b) {
        result = { ...result, cities: [...b].map(({ user_id }) => user_id) };
      }

      return result;
    }
  }, [partnerData, partner]);

  return (
    <Modal
      open={partner ? true : false}
      onCancel={() => setPartner()}
      onOk={() => setPartner()}
      footer={false}
      key={`dd:${partner}`}
      width={600}
    >
      {loading && isEdit && <Skeleton active />}
      {partner && ((!loading && partnerData) || !isEdit) && (
        <Tabs>
          <Tabs.TabPane tab="Шаг 1. Общая информация" key="step1">
            <Form
              onFinish={handleFinishMain}
              initialValues={initProps}
              key={`f:${partner}`}
              layout="vertical"
              style={{ maxWidth: "450px" }}
            >
              <Form.Item name="fio" label="ФИО">
                <Input />
              </Form.Item>

              <Form.Item name="title" label="Название компании">
                <Input />
              </Form.Item>

              <Form.Item name="mail" label="Email">
                <Input />
              </Form.Item>

              <Form.Item name="interest" label="Интересы">
                <Input />
              </Form.Item>

              <Form.Item name="comment" label="Комментарий">
                <Input />
              </Form.Item>

              <Form.Item>
                <Btn type="primary" htmlType="submit">
                  Добавить
                </Btn>
              </Form.Item>
            </Form>
          </Tabs.TabPane>

          <Tabs.TabPane disabled={!isEdit} tab="Шаг 2. Города" key="step2">
            {initProps && (
              <Form
                initialValues={initProps}
                onFinish={(e) => {
                  const partner_id = partner;

                  updateCities({
                    variables: {
                      partner_id,
                      is_global: e.is_global,
                      cities: e.cities.map((user_id) => {
                        return {
                          partner_id,
                          user_id,
                        };
                      }),
                    },
                  });
                }}
                key={`fd:${partner}`}
                layout="vertical"
              >
                <Form.Item name="is_global">
                  <Switch />
                </Form.Item>

                <Form.Item name="cities">
                  <Transfer />
                </Form.Item>

                <Form.Item>
                  <Btn type="primary" htmlType="submit">
                    Сохранить
                  </Btn>
                </Form.Item>
              </Form>
            )}
          </Tabs.TabPane>
        </Tabs>
      )}
    </Modal>
  );
};

export default AddPartner;
